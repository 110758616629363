/** @jsxImportSource theme-ui */
import React from 'react'
import Hr from 'components/Hr'
import ExLink from 'components/ExLink'
import WriteUsForm from 'components/WriteUsForm'
import NextSection from 'components/NextSection'
import { useContacts } from 'store/contacts'
import { Box, Text, AspectRatio, Link } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'

const Map = React.lazy(() => import('components/Map'))

const Contacts = () => {
  const { data: contacts } = useContacts()
  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box sx={{ mb: [20, 40] }}>
        <Text variant="h1" as="h1">
          Контакты
        </Text>
      </Box>
      <Hr sx={{ mb: 20 }} />
      <Box variant="layout.grid2">
        <Box>
          <Text variant="h3">Отдел продаж</Text>
        </Box>
        <Box>
          <Text
            as="div"
            variant="text5"
            sx={{ mt: [10, 0], mb: 20, color: 'gray500' }}
          >
            Контактная информация
          </Text>
          <Box
            sx={{
              gap: [20, 40],
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr']
            }}
          >
            <Box>
              <Text variant="text1" as="div">
                445044, Самарская обл. г. Тольятти, Южное шоссе, д. 103
                <br />
                <br />
                <Link
                  href="tel:+ 7 (8482) 312-000"
                  sx={{ textDecoration: 'none' }}
                >
                  + 7 (8482) 312-000
                </Link>
                <br />
                <br />
                8:00 – 18:00 (пн-пт)
              </Text>
            </Box>
            <Box>
              <Box sx={{ textAlign: ['left', 'right'], mb: 20 }}>
                <ExLink href="mailto:sales@granirf.ru" size="small">
                  sales@granirf.ru
                </ExLink>
              </Box>
              <Box sx={{ textAlign: ['left', 'right'], mb: 20 }}>
                <ExLink
                  size="small"
                  target="_blank"
                  href="https://vk.com/public206709778"
                >
                  Вконтакте
                </ExLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Hr sx={{ mt: [20, 40], mb: 20 }} />
      <Box variant="layout.grid2">
        <Box>
          <Text variant="h3">
            Застройщик
            <br />
            ООО СЗ "Гранит"
          </Text>
        </Box>
        <Box>
          <Text
            as="div"
            variant="text5"
            sx={{ mt: [10, 0], mb: 20, color: 'gray500' }}
          >
            Контактная информация
          </Text>
          <Box
            sx={{
              gap: [20, 40],
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr']
            }}
          >
            <Box>
              <Text variant="text1" as="div">
                445044, Самарская обл. г. Тольятти, Южное шоссе, д. 103А, офис 4
                <br />
                <br />
                <Link
                  href="tel:+ 7 (8482) 312-000"
                  sx={{ textDecoration: 'none' }}
                >
                  + 7 (8482) 950-065
                </Link>
                <br />
                <br />
                8:00 – 17:00 (пн-пт)
              </Text>
            </Box>
            <Box>
              <Box sx={{ textAlign: ['left', 'right'] }}>
                <ExLink href="mailto:smugranit@gmail.com" size="small">
                  smugranit@gmail.com
                </ExLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: [60, 40] }}>
        <AspectRatio ratio={useResponsiveValue([280 / 200, 1360 / 600])}>
          <React.Suspense fallback={null}>
            <Map
              placemarks={contacts.placemarks}
              defaultState={{ zoom: 16, center: contacts.coordinates }}
            />
          </React.Suspense>
        </AspectRatio>
      </Box>
      <Box sx={{ mt: 100, display: ['block', 'none'] }}>
        <Hr />
      </Box>
      <Box sx={{ mt: [20, 200] }}>
        <Box variant="layout.grid2">
          <Box>
            <Text variant="h3">Написать нам</Text>
          </Box>
          <Box sx={{ mt: [10, 0] }}>
            <WriteUsForm />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <NextSection />
      </Box>
    </Box>
  )
}

export default Contacts
