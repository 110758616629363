/** @jsxImportSource theme-ui */
import React from "react";
import { ReactComponent as PrevIcon } from "icons/prev.svg";
import { ReactComponent as NextIcon } from "icons/next.svg";
import { Box, Text, IconButton } from "theme-ui";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { wrap } from "lib/helpers";

const GallerySlider = ({ slides, imageIndex = 0 }) => {
  const [[page, direction], setPage] = React.useState([imageIndex, 0]);

  const paginate = React.useCallback(
    (dir) => {
      setPage([page + dir, dir]);
    },
    [page]
  );

  const prev = React.useCallback(() => paginate(-1), [paginate]);
  const next = React.useCallback(() => paginate(+1), [paginate]);

  const index = wrap(0, slides.length, page);
  const { url, name } = slides[index];

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: ["column-reverse", "column"],
        pt: ["66px", 0],
      }}
    >
      <Box sx={{ flex: "auto", overflow: "hidden" }}>
        <motion.div
          whileInView={{ scale: 1, opacity: 1 }}
          initial={{ scale: 0.6, opacity: 0 }}
          sx={{ width: "100%", height: "100%", overflow: "hidden" }}
          transition={{
            opacity: {
              delay: 0.25,
              duration: 1,
              ease: [0.165, 0.84, 0.44, 1],
            },
            scale: {
              duration: 1.5,
              ease: [0.165, 0.84, 0.44, 1],
            },
          }}
        >
          <motion.div
            whileInView={{ scale: 1 }}
            initial={{ scale: 1.2 }}
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              position: "relative",
            }}
            transition={{
              delay: 0.25,
              scale: {
                duration: 1.5,
                ease: [0.165, 0.84, 0.44, 1],
              },
            }}
          >
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={page}
                custom={direction}
                variants={variants1}
                initial="enter"
                animate="center"
                exit="exit"
                transition={transition}
                sx={containerSx}
              >
                <Box
                  sx={{
                    height: "100%",
                    transform: "translateZ(0)",
                    position: "relative",
                  }}
                >
                  <motion.img
                    src={url}
                    transition={{ duration: 1.5 }}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 2,
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </Box>
      <Box
        variant="layout.grid2"
        sx={{ px: [20, 40], flex: "none", pt: 20, pb: [20, 30] }}
      >
        <Box sx={{ pt: 20, display: ["none", "block"] }}></Box>
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
            }}
          >
            <Box
              sx={{
                width: "7ch",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ overflow: "hidden", position: "relative" }} as="span">
                <Box sx={{ fontSize: 18, width: "2ch" }} />
                <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    key={page}
                    custom={direction}
                    variants={variants2}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    sx={{
                      fontSize: 18,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    {String(index + 1).padStart(2, 0)}
                  </motion.div>
                </AnimatePresence>
              </Box>
              <Box
                as="span"
                sx={{
                  fontSize: 18,
                  color: "gray500",
                  width: "2ch",
                  textAlign: index === 0 ? "left" : "center",
                }}
              >
                —
              </Box>
              <Box
                as="span"
                sx={{
                  fontSize: 18,
                  color: "gray500",
                  width: "2ch",
                }}
              >
                {String(slides.length).padStart(2, 0)}
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={prev}
                sx={{
                  mr: 20,
                  width: "auto",
                  height: "auto",
                  cursor: "pointer",
                  color: "gray500",
                  "&:hover": { color: "text" },
                }}
              >
                <PrevIcon sx={{ width: 17, height: 17 }} />
              </IconButton>
              <IconButton
                onClick={next}
                sx={{
                  width: "auto",
                  height: "auto",
                  cursor: "pointer",
                  color: "gray500",
                  "&:hover": { color: "text" },
                }}
              >
                <NextIcon sx={{ width: 17, height: 17 }} />
              </IconButton>
            </Box>
            <Box sx={{ mt: [10, 20], pl: 1, minHeight: [40, 0] }}>
              <Text variant="text1">{name}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const variants1 = {
  center: { x: 0 },
  enter: (d) => ({ x: d > 0 ? "100%" : "-100%" }),
  exit: (d) => ({ x: d < 0 ? "100%" : "-100%" }),
};

const variants2 = {
  center: { y: 0, scale: 1 },
  enter: (d) => ({ y: d > 0 ? "100%" : "-100%", scale: 0.25 }),
  exit: (d) => ({ y: d < 0 ? "100%" : "-100%", scale: 0.25 }),
};

const transition = {
  x: {
    delay: 0.5,
    duration: 1.5,
    ease: [0.77, 0, 0.175, 1],
  },
};

const containerSx = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  position: "absolute",
};

export default GallerySlider;
