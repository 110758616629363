import GallerySlider from "components/GallerySlider";
import { Box, Link } from "theme-ui";
import { useParams } from "react-router-dom";
import { useProjectsById } from "store/projects";
import { safeJSONParse } from "lib/helpers";

const Project = () => {
  const { hash: imageHash, id, resourceName } = useParams();
  const { data: project = {} } = useProjectsById(useParams().id);
  const content = safeJSONParse(project?.content) ?? [];

  const backUrl = `/${resourceName}/${id}`;

  let gallery, imageIndex;
  outer: for (const block of content) {
    if (block.type === "gallery") {
      for (const [index, image] of block.value.entries()) {
        if (image.hash === imageHash) {
          imageIndex = index + 1;
          gallery = block;
          break outer;
        }
      }
    }
  }

  if ((gallery === void 0 || imageIndex === void 0) && content.length > 0) {
    throw new Error("Gallery not found");
  }

  return (
    <Box
      sx={{
        background: "white",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        color: "text",
        bg: "primary",
      }}
    >
      {gallery && (
        <GallerySlider slides={gallery.value} imageIndex={imageIndex - 1} />
      )}
      <Link href={backUrl}>
        <Box
          sx={{
            bg: "black",
            width: 48,
            height: 46,
            position: "absolute",
            top: [20, 28],
            right: [0, 28],
            left: [0, "auto"],
            margin: ["auto", 0],
            color: "#FDFEFF",
            transition: "0.2s all",
            "&:hover": {
              color: "gray500",
            },
          }}
        >
          {/* prettier-ignore */}
          <svg width="48" height="46" viewBox="0 0 48 46" fill="none">
          <rect width="48" height="46" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.9854 23L35.2714 12.714L34.5643 12.0069L24.2787 22.2925L13.9931 12.0069L13.286 12.714L23.5716 22.9996L13.2856 33.2856L13.9927 33.9927L24.2783 23.7071L34.5639 33.9927L35.271 33.2856L24.9854 23Z" fill="currentColor"/>
          </svg>
        </Box>
      </Link>
    </Box>
  );
};

export default Project;
