import useSWR from 'swr'
import { createResponse } from 'lib/helpers'

export const useDocumentsFindAll = () => {
  let struct

  const { data, error } = useSWR('/documents')
  const all = data?.data

  if (all) {
    struct = []

    const groups = all.filter(d => d.isGroup)
    groups.forEach(group => {
      const documents = all.filter(a => a.parentId === group.id)
      struct.push({ ...group, documents })
    })

    struct = struct.reduce((acc, cur) => {
      const key = cur.complexId ? cur.complex.name : COMMON_GROUP
      acc[key] || (acc[key] = [])
      acc[key].push(cur)
      return acc
    }, {})
  }

  return createResponse({
    data: {
      data: struct
    },
    error
  })
}

const COMMON_GROUP = 'Общие'
