import "slick-carousel/slick/slick.css";

import Slider from "react-slick";
import AnimatedImage from "components/AnimatedImage";
import { useState, useRef } from "react";
import { Box, IconButton, Text, Link } from "theme-ui";
import { ReactComponent as PrevIcon } from "icons/prev.svg";
import { ReactComponent as NextIcon } from "icons/next.svg";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const SliderMd = ({ slides, projectId }) => {
  const slider = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(2);
  const [[page, direction], setPage] = useState([0, 0]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    arrows: false,
    adaptiveHeight: true,
    afterChange: (index) => {
      const i = index + 2;
      const decl = activeIndex - index;
      const dir = decl === 1 || decl === (slides.length - 1) * -1 ? -1 : +1;
      setTextIndex(i >= slides.length ? i - slides.length : i);
      setActiveIndex(index);
      setPage([page + dir, dir]);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const prev = () => {
    if (slider) slider.current.slickPrev();
  };

  const next = () => {
    if (slider) slider.current.slickNext();
  };

  return (
    <Box>
      <Box
        sx={{
          mx: [-10, 0],
          "& .slick-slide": {
            transition: "all 0.6s",
            transform: ["none", "scale(0.9)"],
            transformOrigin: "top left",
            transitionDelay: "0.2s",
          },
          "& .slick-slide.slick-current + .slick-slide + .slick-slide": {
            transform: ["none", "scale(1.15) translateX(0)"],
            transformOrigin: "top right",
            zIndex: 999,
            position: "relative",
          },
          "& .slick-slide.slick-current + .slick-slide": {
            transform: ["none", "scale(0.9) translateX(-8.5%)"],
          },
        }}
      >
        <Slider {...settings} ref={slider}>
          {slides.map((slide) => (
            <Box key={slide.hash} sx={{ px: [10, 0] }}>
              <Link href={`/gallery/projects/${projectId}/${slide.hash}`}>
                <AnimatedImage src={slide.url} animateHover={true} />
              </Link>
            </Box>
          ))}
        </Slider>
      </Box>
      {slider && (
        <Box variant="layout.grid2" sx={{ px: [0, 40] }}>
          <Box />
          <Box>
            <Box
              sx={{
                mt: [20, 35],
                display: "grid",
                gridTemplateColumns: "1fr auto",
              }}
            >
              <Box
                sx={{
                  width: "7ch",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                    maxHeight: 18,
                  }}
                  as="span"
                >
                  <Box sx={{ fontSize: 18, width: "2ch" }}>
                    <AnimatePresence initial={false} custom={direction}>
                      <motion.div
                        key={page}
                        custom={direction}
                        variants={variants2}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        sx={{
                          fontSize: 18,
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      >
                        {String(activeIndex + 1).padStart(2, 0)}
                      </motion.div>
                    </AnimatePresence>
                  </Box>
                </Box>
                <Box
                  as="span"
                  sx={{
                    fontSize: 18,
                    color: "gray500",
                    width: "2ch",
                    textAlign: activeIndex === 0 ? "left" : "center",
                  }}
                >
                  —
                </Box>
                <Box
                  as="span"
                  sx={{
                    fontSize: 18,
                    color: "gray500",
                    width: "2ch",
                  }}
                >
                  {String(slides.length).padStart(2, 0)}
                </Box>
              </Box>
              <Box>
                <IconButton
                  onClick={prev}
                  sx={{
                    mr: 20,
                    width: "auto",
                    height: "auto",
                    cursor: "pointer",
                    color: "gray500",
                    "&:hover": { color: "text" },
                  }}
                >
                  <PrevIcon sx={{ width: 17, height: 17 }} />
                </IconButton>
                <IconButton
                  onClick={next}
                  sx={{
                    width: "auto",
                    height: "auto",
                    cursor: "pointer",
                    color: "gray500",
                    "&:hover": { color: "text" },
                  }}
                >
                  <NextIcon sx={{ width: 17, height: 17 }} />
                </IconButton>
              </Box>
              {slides[activeIndex] && (
                <Box sx={{ mt: 20, pl: 1, display: ["none", "block"] }}>
                  <Text variant="text1">{slides[textIndex].name}</Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const variants2 = {
  center: { y: 0, scale: 1 },
  enter: (d) => ({ y: d > 0 ? "100%" : "-100%", scale: 0.25 }),
  exit: (d) => ({ y: d < 0 ? "100%" : "-100%", scale: 0.25 }),
};

export default SliderMd;
