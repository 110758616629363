/** @jsxImportSource theme-ui */
import Hr from 'components/Hr'
import ExLink from 'components/ExLink'
import RouterLink from 'components/RouterLink'
import WriteUsForm from 'components/WriteUsForm'
import NextSection from 'components/NextSection'
import AnimatedImage from 'components/AnimatedImage'
import VideoSliderMd from 'components/VideoSlider.md'
import { useNewsFindAll } from 'store/news'
import { ReactComponent as AuIcon } from 'icons/au.svg'
import { Box, Text, Image, AspectRatio } from 'theme-ui'
import { humanDate } from 'lib/helpers'
import { motion } from 'framer-motion/dist/framer-motion'

const duration = 3
const accentColor = '#638EFC'
const ease = [0.165, 0.84, 0.44, 1]

const Home = () => {
  const { data: news } = useNewsFindAll({ count: 3 })
  return (
    <Box sx={{ pb: 20, pt: [100, 200] }}>
      <Box sx={{ mb: [20, 40], color: 'text' }}>
        {/* prettier-ignore */}
        <svg width="100%" height="100%" viewBox="0 0 1352 261" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="261">
            <rect width="183" height="261" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_2_7)">
            <motion.path
              d="M0 261H71.3977V49.6194H168.754V0H0V261Z"
              fill="currentColor"
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              transition={{ duration, ease }}
            />
            <motion.path
              d="M0 261H71.3977V49.6194H168.754V0H0V261Z"
              fill={accentColor}
              initial={{ x: '-50%' }}
              animate={{ x: 0, opacity: [1, 0] }}
              transition={{
                duration,
                ease,
                opacity: {
                  delay: duration,
                  duration: 0.5
                }
              }}
            />
          </g>
          <mask id="mask2_2_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="186" y="0" width="270" height="261">
            <rect x="186" width="270" height="261" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask2_2_7)">
            <motion.path
              d="M186.01 261V0H362.53C396.284 0 420.953 9.02206 437.171 26.4262C449.5 40.5993 455.342 57.9931 455.342 79.258C455.342 107.615 448.201 129.53 433.272 144.993C418.344 160.467 396.929 168.199 368.382 168.199H257.387V261H186H186.01ZM257.398 120.508H333.983C348.911 120.508 359.941 119.217 366.428 115.997C376.813 110.835 382.001 100.533 382.001 85.0593C382.001 69.5958 377.458 59.2835 369.027 54.1324C363.184 50.2614 353.454 48.331 340.47 48.331H257.398V47.6807V120.508Z"
              fill="currentColor"
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              transition={{ duration, ease }}
            />
            <motion.path
              d="M186.01 261V0H362.53C396.284 0 420.953 9.02206 437.171 26.4262C449.5 40.5993 455.342 57.9931 455.342 79.258C455.342 107.615 448.201 129.53 433.272 144.993C418.344 160.467 396.929 168.199 368.382 168.199H257.387V261H186H186.01ZM257.398 120.508H333.983C348.911 120.508 359.941 119.217 366.428 115.997C376.813 110.835 382.001 100.533 382.001 85.0593C382.001 69.5958 377.458 59.2835 369.027 54.1324C363.184 50.2614 353.454 48.331 340.47 48.331H257.398V47.6807V120.508Z"
              fill={accentColor}
              initial={{ x: '-50%' }}
              animate={{ x: 0, opacity: [1, 0] }}
              transition={{
                duration,
                ease,
                opacity: {
                  delay: duration + 0.1,
                  duration: 0.5
                }
              }}
            />
          </g>
          <mask id="mask1_2_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="414" y="0" width="318" height="261">
            <path d="M528 0H732V261H414L528 0Z" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask1_2_7)">
            <motion.path
              d="M654.796 261L633.37 207.499H512.655L491.24 260.99H414L529.528 0H615.843L731.371 260.99H654.785L654.796 261ZM613.899 159.821L572.368 56.0812L530.827 159.821H613.899Z"
              fill="currentColor"
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              transition={{ duration, ease }}
            />
            <motion.path
              d="M654.796 261L633.37 207.499H512.655L491.24 260.99H414L529.528 0H615.843L731.371 260.99H654.785L654.796 261ZM613.899 159.821L572.368 56.0812L530.827 159.821H613.899Z"
              fill={accentColor}
              initial={{ x: '-50%' }}
              animate={{ x: 0, opacity: [1, 0] }}
              transition={{
                duration,
                ease,
                opacity: {
                  delay: duration + 0.2,
                  duration: 0.5
                }
              }}
            />
          </g>
          <mask id="mask3_2_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="748" y="0" width="291" height="261">
            <rect x="748" width="291" height="261" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask3_2_7)">
            <motion.path
              d="M748 261V0.0103167H819.398V97.9589H967.37V0H1038.76V261H967.37V147.578H819.398V261H748Z"
              fill="currentColor"
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              transition={{ duration, ease }}
            />
            <motion.path
              d="M748 261V0.0103167H819.398V97.9589H967.37V0H1038.76V261H967.37V147.578H819.398V261H748Z"
              fill={accentColor}
              initial={{ x: '-50%' }}
              animate={{ x: 0, opacity: [1, 0] }}
              transition={{
                duration,
                ease,
                opacity: {
                  delay: duration + 0.3,
                  duration: 0.5
                }
              }}
            />
          </g>
          <mask id="mask4_2_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1061" y="0" width="291" height="261">
            <rect x="1061" width="291" height="261" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask4_2_7)">
            <motion.path
              d="M1130.44 181.721L1268.68 0H1351.75V261H1282.31V79.2683L1138.88 261H1061V0H1130.44V181.732V181.721Z"
              fill="currentColor"
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              transition={{ duration, ease }}
            />
            <motion.path
              d="M1130.44 181.721L1268.68 0H1351.75V261H1282.31V79.2683L1138.88 261H1061V0H1130.44V181.732V181.721Z"
              fill={accentColor}
              initial={{ x: '-50%' }}
              animate={{ x: 0, opacity: [1, 0] }}
              transition={{
                duration,
                ease,
                opacity: {
                  delay: duration + 0.4,
                  duration: 0.5
                }
              }}
            />
          </g>
        </svg>
      </Box>
      <Box sx={{ width: ['100%', 'calc(100% + 80px)'], ml: [0, -40] }}>
        <VideoSliderMd slides={slides} />
      </Box>
      <Hr sx={{ mt: [100, 200], mb: [20, 50] }} />
      <Box variant="layout.grid2">
        <Box>
          <Text variant="h2">
            «ГРАНИ» — <br />
            Современная <br />
            девелоперская <br />
            компания
          </Text>
        </Box>
        <Box>
          <Box sx={{ display: ['none', 'block'], mb: 20 }}>
            <Text variant="text4">Мы развиваем идеи:</Text>
          </Box>
          <Box sx={{ display: ['block', 'none'], mt: 20, mb: 10 }}>
            <Text
              sx={{ fontSize: 16, fontWeight: 600, textTransform: 'uppercase' }}
            >
              Мы развиваем идеи:
            </Text>
          </Box>
          <Text variant="text1">
            чувства защищённости, уверенности в будущем
          </Text>
          <Hr sx={{ mt: [10, 20], mb: [10, 20] }} />
          <Text variant="text1">
            комфортного партнёрства, уважения к своим потребностям
          </Text>
          <Hr sx={{ mt: [10, 20], mb: [10, 20] }} />
          <Text variant="text1">удовлетворённости от жизни и счастья</Text>
          <Hr sx={{ mt: [10, 20], mb: [20, 40] }} />
          <ExLink size="small" href="/company">
            О компании
          </ExLink>
        </Box>
      </Box>
      <Hr sx={{ mt: [100, 180], mb: [20, 50] }} />
      <Box>
        <Text variant="h3">
          Для нас строительство — не самоцель, а возможность поразмышлять о том,
          зачем вообще людям нужна недвижимость
        </Text>
      </Box>
      <Box sx={{ pl: [0, (360 * 100) / 1360 + '%'], mt: [40, 80] }}>
        <AnimatedImage
          ratio={1000 / 700}
          src={require('images/i19.jpg').default}
        />
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <Text as="div" variant="h2">
          Недвижимость
        </Text>
        <Hr sx={{ mt: [20, 40] }} />
        <RouterLink
          href="/projects"
          sx={{
            textDecoration: 'none',
            '&:hover': { textDecoration: 'none' },
            '&:hover [data-name="hr"]': {
              bg: 'text',
              opacity: 1
            },
            '&:hover [data-name="heading"]': {
              textDecoration: 'underline'
            }
          }}
        >
          <Hr sx={{ opacity: 0, mb: 20 }} data-name="hr" />
          <Box variant="layout.grid2">
            <Box>
              <Text variant="h3" data-name="heading">
                ЖИЛАЯ
                <AuIcon
                  sx={{
                    ml: 10,
                    width: 12,
                    height: 12,
                    position: 'relative',
                    top: -2,
                    display: ['inline', 'none']
                  }}
                />
              </Text>
            </Box>
            <Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                <Box sx={{ pt: [14, 7] }}>
                  <Text variant="text1">Реализуем проекты для жизни</Text>
                </Box>
                <Box sx={{ pt: 12, display: ['none', 'block'] }}>
                  <AuIcon sx={{ width: 26, height: 26 }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </RouterLink>
        <Hr sx={{ mt: 40 }} />
        <RouterLink
          href="/rent"
          sx={{
            textDecoration: 'none',
            '&:hover': { textDecoration: 'none' },
            '&:hover [data-name="hr"]': {
              bg: 'text',
              opacity: 1
            },
            '&:hover [data-name="heading"]': {
              textDecoration: 'underline'
            }
          }}
        >
          <Hr sx={{ opacity: 0, mb: 20 }} data-name="hr" />
          <Box variant="layout.grid2">
            <Box>
              <Text variant="h3" data-name="heading">
                Коммерческая
                <AuIcon
                  sx={{
                    ml: 10,
                    width: 12,
                    height: 12,
                    position: 'relative',
                    top: -2,
                    display: ['inline', 'none']
                  }}
                />
              </Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
              <Box sx={{ pt: [14, 7] }}>
                <Text variant="text1">
                  Предоставляем в аренду торговые и офисные помещения
                </Text>
              </Box>
              <Box sx={{ pt: 12, display: ['none', 'block'] }}>
                <AuIcon sx={{ width: 26, height: 26 }} />
              </Box>
            </Box>
          </Box>
        </RouterLink>
      </Box>
      <Hr sx={{ mt: [100, 200] }} />
      <Box sx={{ mt: [20, 50] }}>
        <Text as="div" variant="h2">
          В группу компаний «ГРАНИ» входят
        </Text>
        <Box sx={{ display: ['block', 'none'], mt: 20 }}>
          <RouterLink href="/company/1">
            <Box sx={{ pr: 40, position: 'relative' }}>
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textTransform: 'uppercase'
                }}
              >
                СПЕЦ. ЗАСТРОЙЩИК «ГРАНИТ»
                <AuIcon
                  sx={{
                    width: 12,
                    height: 12,
                    position: 'absolute',
                    top: 5,
                    right: 0
                  }}
                />
              </Text>
            </Box>
          </RouterLink>
          <Hr sx={{ my: 10 }} />
          <RouterLink href="/company/2">
            <Box sx={{ pr: 40, position: 'relative' }}>
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textTransform: 'uppercase'
                }}
              >
                «СТРОЙ ИНЖИНИРИНГ»
                <AuIcon
                  sx={{
                    width: 12,
                    height: 12,
                    position: 'absolute',
                    top: 5,
                    right: 0
                  }}
                />
              </Text>
            </Box>
          </RouterLink>
          <Hr sx={{ my: 10 }} />
          <RouterLink href="/company/3">
            <Box sx={{ pr: 40, position: 'relative' }}>
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textTransform: 'uppercase'
                }}
              >
                «4ГРАНИ»
                <AuIcon
                  sx={{
                    width: 12,
                    height: 12,
                    position: 'absolute',
                    top: 5,
                    right: 0
                  }}
                />
              </Text>
            </Box>
          </RouterLink>
          <Hr sx={{ my: 10 }} />
          <RouterLink href="/company/4">
            <Box sx={{ pr: 40, position: 'relative' }}>
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textTransform: 'uppercase'
                }}
              >
                «БЕТОН ГРАНИТ»
                <AuIcon
                  sx={{
                    width: 12,
                    height: 12,
                    position: 'absolute',
                    top: 5,
                    right: 0
                  }}
                />
              </Text>
            </Box>
          </RouterLink>
          <Hr sx={{ my: 10 }} />
          <RouterLink href="/company/5">
            <Box sx={{ pr: 40, position: 'relative' }}>
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textTransform: 'uppercase'
                }}
              >
                «СТРОИТЕЛЬНАЯ ЛАБОРАТОРИЯ ГОСТ»
                <AuIcon
                  sx={{
                    width: 12,
                    height: 12,
                    position: 'absolute',
                    top: 5,
                    right: 0
                  }}
                />
              </Text>
            </Box>
          </RouterLink>
          <Hr sx={{ mt: 10 }} />
        </Box>
        <Box sx={{ display: ['none', 'block'] }}>
          <Box
            sx={{
              mt: 40,
              display: 'grid',
              gap: 40,
              gridTemplateColumns: 'repeat(5, 1fr)'
            }}
          >
            <RouterLink
              href="/company/1"
              sx={{
                position: 'relative',
                '&:hover': {
                  textDecoration: 'none',
                  borderBottomColor: 'text'
                },
                '&:hover [data-name="hr"]': { bg: 'text' },
                '&:hover img': { opacity: 1, filter: 'grayscale(0)' },
                borderBottom: 'solid 1px rgba(253, 254, 255, 0.5)',
                pb: 20
              }}
            >
              <Hr
                data-name="hr"
                sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
              />
              <AspectRatio ratio={240 / 330}>
                <Image
                  src={require('images/i27.jpg').default}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 0.5,
                    filter: 'grayscale(1)'
                  }}
                />
              </AspectRatio>
              <Text variant="text1" as="div" sx={{ mt: 20 }}>
                СПЕЦ. ЗАСТРОЙЩИК «ГРАНИТ»
              </Text>
            </RouterLink>
            <RouterLink
              href="/company/2"
              sx={{
                position: 'relative',
                '&:hover': {
                  textDecoration: 'none',
                  borderBottomColor: 'text'
                },
                '&:hover [data-name="hr"]': { bg: 'text' },
                '&:hover img': { opacity: 1, filter: 'grayscale(0)' },
                borderBottom: 'solid 1px rgba(253, 254, 255, 0.5)',
                pb: 20
              }}
            >
              <Hr
                data-name="hr"
                sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
              />
              <AspectRatio ratio={240 / 330}>
                <Image
                  src={require('images/i28.jpg').default}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 0.5,
                    filter: 'grayscale(1)'
                  }}
                />
              </AspectRatio>
              <Text variant="text1" as="div" sx={{ mt: 20 }}>
                «СТРОЙ ИНЖИНИРИНГ»
              </Text>
            </RouterLink>
            <RouterLink
              href="/company/3"
              sx={{
                position: 'relative',
                '&:hover': {
                  textDecoration: 'none',
                  borderBottomColor: 'text'
                },
                '&:hover [data-name="hr"]': { bg: 'text' },
                '&:hover img': { opacity: 1, filter: 'grayscale(0)' },
                borderBottom: 'solid 1px rgba(253, 254, 255, 0.5)',
                pb: 20
              }}
            >
              <Hr
                data-name="hr"
                sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
              />
              <AspectRatio ratio={240 / 330}>
                <Image
                  src={require('images/i29.jpg').default}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 0.5,
                    filter: 'grayscale(1)'
                  }}
                />
              </AspectRatio>
              <Text variant="text1" as="div" sx={{ mt: 20 }}>
                «4ГРАНИ»
              </Text>
            </RouterLink>
            <RouterLink
              href="/company/4"
              sx={{
                position: 'relative',
                '&:hover': {
                  textDecoration: 'none',
                  borderBottomColor: 'text'
                },
                '&:hover [data-name="hr"]': { bg: 'text' },
                '&:hover img': { opacity: 1, filter: 'grayscale(0)' },
                borderBottom: 'solid 1px rgba(253, 254, 255, 0.5)',
                pb: 20
              }}
            >
              <Hr
                data-name="hr"
                sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
              />
              <AspectRatio ratio={240 / 330}>
                <Image
                  src={require('images/i30.jpg').default}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 0.5,
                    filter: 'grayscale(1)'
                  }}
                />
              </AspectRatio>
              <Text variant="text1" as="div" sx={{ mt: 20 }}>
                «БЕТОН ГРАНИТ»
              </Text>
            </RouterLink>
            <RouterLink
              href="/company/5"
              sx={{
                position: 'relative',
                '&:hover': {
                  textDecoration: 'none',
                  borderBottomColor: 'text'
                },
                '&:hover [data-name="hr"]': { bg: 'text' },
                '&:hover img': { opacity: 1, filter: 'grayscale(0)' },
                borderBottom: 'solid 1px rgba(253, 254, 255, 0.5)',
                pb: 20
              }}
            >
              <Hr
                data-name="hr"
                sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
              />
              <AspectRatio ratio={240 / 330}>
                <Image
                  src={require('images/i31.jpg').default}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 0.5,
                    filter: 'grayscale(1)'
                  }}
                />
              </AspectRatio>
              <Text variant="text1" as="div" sx={{ mt: 20 }}>
                «СТРОИТЕЛЬНАЯ ЛАБОРАТОРИЯ ГОСТ»
              </Text>
            </RouterLink>
          </Box>
        </Box>
      </Box>
      {news && (
        <Box sx={{ mt: [100, 200] }}>
          <Text variant="h2" as="div" sx={{ mb: [20, 40] }}>
            Новости
          </Text>
          {news.map(news => (
            <RouterLink
              key={news.id}
              href={`/news/${news.id}`}
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'none' }
              }}
            >
              <Box sx={{ display: ['none', 'block'] }}>
                <Box
                  sx={{
                    '&:hover [data-name="hr"]': {
                      bg: 'text',
                      opacity: 1
                    },
                    '&:hover [data-name="heading"]': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  <Hr sx={{ mt: 40 }} data-name="hr" />
                  <Hr sx={{ mb: 20, opacity: 0 }} data-name="hr" />
                  <Box variant="layout.grid2">
                    <Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr auto'
                        }}
                      >
                        <Box>
                          <Text variant="text4" data-name="heading">
                            {news.name}
                          </Text>
                        </Box>
                        <Box sx={{ pt: 2 }}>
                          <Text variant="text1" sx={{ color: 'gray500' }}>
                            {humanDate(news.publishedAt, {})}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr auto'
                        }}
                      >
                        <Box>
                          <Text variant="text1">{news.annotation}</Text>
                        </Box>
                        <Box sx={{ pt: 7 }}>
                          <AuIcon sx={{ width: [22, 18], height: [22, 18] }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: ['block', 'none'] }}>
                <Hr sx={{ mt: 20, mb: 10 }} />
                <Text variant="text1" sx={{ color: 'gray500' }} as="div">
                  {humanDate(news.publishedAt, {})}
                </Text>
                <Box sx={{ my: 10, position: 'relative', pr: 40 }}>
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      textTransform: 'uppercase'
                    }}
                  >
                    {news.name}
                  </Text>
                  <AuIcon
                    sx={{
                      width: 12,
                      height: 12,
                      position: 'absolute',
                      top: 3,
                      right: 0
                    }}
                  />
                </Box>
                <Box>
                  <Text variant="text1">{news.annotation}</Text>
                </Box>
              </Box>
            </RouterLink>
          ))}
        </Box>
      )}
      <Box sx={{ mt: [100, 200] }}>
        <Hr sx={{ mb: [20, 50] }} />
        <Box variant="layout.grid2">
          <Box>
            <Text variant="h2">Написать нам</Text>
          </Box>
          <Box sx={{ mt: [10, 0] }}>
            <WriteUsForm />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: [50, 200] }}>
        <NextSection />
      </Box>
    </Box>
  )
}

const slides = [
  [
    '1-min.jpg',
    '1.mp4',
    '1.webm',
    'ДЕВЕЛОПЕРСКАЯ КОМПАНИЯ',
    'Наш фокус шире, чем просто строительство'
  ],
  [
    '2-min.jpg',
    '2.mp4',
    '2.webm',
    'ГЕОМЕТРИЯ КОМФОРТА',
    'Ставим во главу проектирование, строительство и удобство эксплуатации'
  ],
  [
    '3-min.jpg',
    '3.mp4',
    '3.webm',
    'НЕДВИЖИМОСТЬ ДЛЯ ЖИЗНИ',
    'С нашей недвижимостью не хочется расставаться'
  ]
]

export default Home
