import { Box } from 'theme-ui'
import CookieInformer from 'components/CookieInformer'
import Footer from 'components/Footer'
import HeaderMd from 'components/Header.md'
import HeaderSm from 'components/Header.sm'
import NoIndex from './NoIndex'
import React from 'react'
import { cookieInformerAtom } from 'state'
import { useColorMode } from 'theme-ui'
import { useRecoilValue } from 'recoil'

const Layout = ({ children, header = true, footer = true }) => {
  const [colorMode] = useColorMode()
  const cookieInformer = useRecoilValue(cookieInformerAtom)

  return (
    <Box
      key={colorMode}
      sx={{
        display: 'grid',
        minHeight: '100vh',
        gridTemplateRows: 'auto 1fr auto'
      }}
    >
      <Box>
        {header && (
          <>
            <Box sx={{ display: ['none', 'block'] }}>
              <HeaderMd />
            </Box>
            <Box sx={{ display: ['block', 'none'] }}>
              <HeaderSm />
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ px: [20, 40] }}>{children}</Box>
      <Box sx={{ px: [20, 40] }}>{footer && <Footer />}</Box>
      {cookieInformer && (
        <Box sx={{ position: 'fixed', left: 0, right: 0, bottom: 0, zIndex: 2 }}>
          <NoIndex>
            <CookieInformer />
          </NoIndex>
        </Box>
      )}
    </Box>
  )
}

export default Layout
