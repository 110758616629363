/** @jsxImportSource theme-ui */
import React from 'react'
import RouterLink from 'components/RouterLink'
import Switch from 'components/Switch'
import Headroom from 'react-headroom'
import { Box, Link } from 'theme-ui'
import { ReactComponent as LogoIcon } from 'icons/logo.svg'
import { useColorMode } from 'theme-ui'

const HeaderMd = () => {
  const [colorMode, setColorMode] = useColorMode()

  const toggleColorMode = React.useCallback(() => {
    if (colorMode === 'light') setColorMode('dark')
    else setColorMode('light')
  }, [colorMode, setColorMode])

  return (
    <Headroom>
      <Box
        as="header"
        sx={{
          px: 40,
          pb: 26,
          bg: 'primary',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto'
        }}
      >
        <Box sx={{ pt: 24 }}>
          <RouterLink href="/">
            <LogoIcon sx={{ width: 165, height: 50, color: 'text' }} />
          </RouterLink>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 54 }}>
          <Box sx={{ mx: 20 }}>
            <RouterLink href="/company" sx={linkSx} activeClassName="selected">
              Компания
            </RouterLink>
          </Box>
          <Box sx={{ mx: 20 }}>
            <RouterLink href="/projects" sx={linkSx} activeClassName="selected">
              Проекты
            </RouterLink>
          </Box>
          <Box sx={{ mx: 20 }}>
            <RouterLink href="/rent" sx={linkSx} activeClassName="selected">
              Аренда
            </RouterLink>
          </Box>
          <Box sx={{ mx: 20 }}>
            <RouterLink href="/news" sx={linkSx} activeClassName="selected">
              Новости
            </RouterLink>
          </Box>
          <Box sx={{ mx: 20 }}>
            <RouterLink
              href="/documents"
              sx={linkSx}
              activeClassName="selected"
            >
              Документы
            </RouterLink>
          </Box>
          <Box sx={{ mx: 20 }}>
            <RouterLink href="/contacts" sx={linkSx} activeClassName="selected">
              Контакты
            </RouterLink>
          </Box>
        </Box>
        <Box sx={{ pt: 54, position: 'relative' }}>
          <Link
            href="tel:+ 7 (8482) 312-000"
            sx={{
              fontSize: 16,
              fontWeight: 700,
              textTransform: 'uppercase',
              color: 'text',
              textDecoration: 'none'
            }}
          >
            + 7 (8482) 312-000
          </Link>
          <Box sx={{ position: 'absolute', right: 0, top: 36 }}>
            <Switch onClick={toggleColorMode} />
          </Box>
        </Box>
      </Box>
    </Headroom>
  )
}

const linkSx = {
  fontSize: '16px',
  textTransform: 'uppercase',
  color: 'text',
  fontWeight: 600,
  '&.selected': {
    color: 'gray500'
  },
  '&:hover': {
    color: 'gray500',
    textDecoration: 'none'
  }
}

export default HeaderMd
