/** @jsxImportSource theme-ui */
import ExLink from "components/ExLink";
import Hr from "components/Hr";
import NextSection from "components/NextSection";
import { useRentFindAll } from "store/rent";
import { Box, Text, Image, AspectRatio } from "theme-ui";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";

const RentList = () => {
  const { data: rent } = useRentFindAll();
  const history = useHistory();

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box sx={{ mb: [20, 40] }}>
        <Text variant="h1" as="h1">
          Аренда
        </Text>
      </Box>
      <Hr sx={{ mb: [20, 40] }} />
      {rent && (
        <Box>
          {rent.map((rent, index, arr) => (
            <motion.div key={rent.id} whileHover="enter">
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => history.push(`/rent/${rent.id}`)}
              >
                <Box variant="layout.grid2">
                  <Box>
                    {rent.subName && (
                      <Text
                        variant="text4"
                        as="h2"
                        sx={{ color: "gray500", mb: [10, 20] }}
                      >
                        {rent.status}
                      </Text>
                    )}
                    <Text variant="h3" as="h2" sx={{ mb: 20 }}>
                      {rent.name}
                    </Text>
                    <Text variant="text1" as="div">
                      {rent.address}
                    </Text>
                    <Text variant="text2" as="div" sx={{ mt: [20, 40] }}>
                      <ExLink
                        size="small"
                        href={`/rent/${rent.id}`}
                        onClick={(ev) => ev.stopPropagation()}
                      >
                        Перейти к проекту
                      </ExLink>
                    </Text>
                  </Box>
                  <Box sx={{ mt: [20, 0] }}>
                    <AspectRatio ratio={587 / 360}>
                      <motion.div
                        transition={imageTransition}
                        variants={imageVariants}
                        sx={{ width: "100%", height: "100%" }}
                      >
                        <Image
                          src={rent.pictureUrl}
                          sx={{
                            display: "block",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </motion.div>
                    </AspectRatio>
                  </Box>
                </Box>
                {index < arr.length - 1 && (
                  <Hr sx={{ mt: [40, 40], mb: [20, 40] }} />
                )}
              </Box>
            </motion.div>
          ))}
        </Box>
      )}
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="Проекты" href="/projects" />
      </Box>
    </Box>
  );
};

const imageVariants = {
  enter: { scale: 1.05 },
};

const imageTransition = {
  ease: [0, 0.55, 0.45, 1],
  duration: 0.6,
};

export default RentList;
