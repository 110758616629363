import { Box, Text } from "theme-ui";
import { sanitizeHTML } from "lib/helpers";

export const Ul = ({ options }) => {
  return (
    <Box as="ul" sx={{ listStyle: "none", margin: 0, padding: 0 }}>
      {options.map((name, index) => (
        <Box
          as="li"
          key={index}
          sx={{
            margin: 0,
            padding: 0,
            display: "grid",
            gridTemplateColumns: "auto 1fr",
          }}
        >
          <Box sx={{ fontSize: "0.65em", pr: 10, pt: "0.15em" }}>●</Box>
          <Box as="span">{name}</Box>
        </Box>
      ))}
    </Box>
  );
};

export const Heading = ({ value, meta }) => {
  const Component = components.get(meta.variant);
  return Component ? <Component value={value} /> : null;
};

export const TextBlock = ({ value }) => {
  const html = sanitizeHTML(value);
  return (
    <Box variant="layout.grid2" mb={[100, 80]}>
      <Box />
      <Text variant="text1" dangerouslySetInnerHTML={{ __html: html }}></Text>
    </Box>
  );
};

const H2 = ({ value }) => (
  <Box mb={[20, 40]}>
    <Text variant="h2">{value}</Text>
  </Box>
);
const H3 = ({ value }) => (
  <Box variant="layout.grid2" mb={[20, 40]}>
    <Box />
    <Text variant="h3" sx={{ fontSize: [20, 25] }}>
      {value}
    </Text>
  </Box>
);

const components = new Map([
  ["h2", H2],
  ["h3", H3],
]);
