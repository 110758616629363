/** @jsxImportSource theme-ui */
import ExLink from "components/ExLink";
import Hr from "components/Hr";
import NextSection from "components/NextSection";
import { Box, Text, Image, AspectRatio } from "theme-ui";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { useProjects } from "store/projects";

const ProjectList = () => {
  const history = useHistory();
  const { data: projects = [] } = useProjects();

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box sx={{ mb: [20, 40] }}>
        <Text variant="h1" as="h1">
          Проекты
        </Text>
      </Box>
      {projects.map((project) => (
        <Box key={project.id}>
          <Hr sx={{ mb: [20, 40] }} />
          <Box>
            <motion.div whileHover="enter">
              <Box variant="layout.grid2">
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => history.push(`/projects/${project.id}`)}
                >
                  {project.subName && (
                    <Text variant="text4" as="div" sx={{ color: "gray500" }}>
                      {project.subName}
                    </Text>
                  )}
                  <Text variant="h3" as="h2" sx={{ mt: [10, 20], mb: 20 }}>
                    {project.name}
                  </Text>
                  <Text variant="text1" as="div">
                    {project.address}
                  </Text>
                  <Text
                    as="div"
                    variant="text2"
                    sx={{ mt: [20, 40], display: ["none", "block"] }}
                  >
                    <ExLink
                      href={`/projects/${project.id}`}
                      onClick={(ev) => ev.stopPropagation()}
                      size="small"
                    >
                      Перейти к проекту
                    </ExLink>
                  </Text>
                </Box>
                <Box sx={{ mt: [20, 0] }}>
                  <AspectRatio ratio={587 / 332}>
                    <motion.div
                      transition={imageTransition}
                      variants={imageVariants}
                      sx={{ width: "100%", height: "100%" }}
                    >
                      {project.pictureUrl && (
                        <Image
                          src={project.pictureUrl}
                          sx={{
                            display: "block",
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </motion.div>
                  </AspectRatio>
                </Box>
              </Box>
            </motion.div>
          </Box>
        </Box>
      ))}
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="Аренда" href="/rent" />
      </Box>
    </Box>
  );
};

const imageVariants = {
  enter: { scale: 1.05 },
};

const imageTransition = {
  ease: [0, 0.55, 0.45, 1],
  duration: 0.6,
};

export default ProjectList;
