/** @jsxImportSource theme-ui */
import React from 'react'
import { ReactComponent as PrevIcon } from 'icons/prev.svg'
import { ReactComponent as NextIcon } from 'icons/next.svg'
import { Box, Text, AspectRatio, IconButton } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { wrap } from 'lib/helpers'

const VideoSlider = ({ slides }) => {
  const [ready, setReady] = React.useState(false)
  const [running, setRunning] = React.useState(false)
  const [[page, direction], setPage] = React.useState([0, 0])

  const paginate = React.useCallback(
    dir => {
      if (!running) {
        setPage([page + dir, dir])
        setRunning(true)
      }
    },
    [page, running]
  )

  const prev = React.useCallback(() => paginate(-1), [paginate])
  const next = React.useCallback(() => paginate(+1), [paginate])

  const index = wrap(0, slides.length, page)
  const [, mp4, webm, text1, text2] = slides[index]
  const [image] = slides[0]

  // TODO: fix warning play/pause async
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const videoRef = React.useRef([])
  React.useEffect(() => {
    try {
      videoRef.current.forEach(v => v?.pause())
      if (!running) {
        videoRef.current[page]?.play()
      }
    } catch (error) {}
  }, [page, running])

  React.useEffect(() => {
    const video = videoRef.current[page]
    if (video) {
      video.addEventListener('ended', next)
      return () => video.removeEventListener('ended', next)
    }
  }, [page, next])

  React.useEffect(() => {
    const video = videoRef.current[0]
    const handler = () => setReady(true)
    if (video) {
      video.addEventListener('canplay', handler)
      return () => video.removeEventListener('canplay', handler)
    }
  }, [])

  return (
    <Box>
      <Box
        sx={{
          '& video': {
            transform: running ? 'scale(1.035)' : 'scale(1)',
            transition: '1.75s cubic-bezier(.165,.84,.44,1)'
          }
        }}
      >
        <AspectRatio ratio={1360 / 600} sx={{ overflow: 'visible' }}>
          <motion.div
            whileInView={{ scale: 1, opacity: 1 }}
            initial={{ scale: 0.6, opacity: 0 }}
            sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
            transition={{
              opacity: {
                delay: 0.25,
                duration: 1,
                ease: [0.165, 0.84, 0.44, 1]
              },
              scale: {
                duration: 1.5,
                ease: [0.165, 0.84, 0.44, 1]
              }
            }}
          >
            <motion.div
              whileInView={{ scale: 1 }}
              initial={{ scale: 1.2 }}
              sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
              transition={{
                delay: 0.25,
                scale: {
                  duration: 1.5,
                  ease: [0.165, 0.84, 0.44, 1]
                }
              }}
            >
              <AnimatePresence initial={false} custom={direction}>
                <motion.div
                  key={page}
                  custom={direction}
                  variants={variants1}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={transition}
                  sx={containerSx}
                  onAnimationComplete={() => setRunning(false)}
                >
                  <Box
                    sx={{
                      mx: [0, 40],
                      height: '100%',
                      transform: 'translateZ(0)'
                    }}
                  >
                    <motion.img
                      src={`/slider/${image}`}
                      animate={{ opacity: +!ready }}
                      transition={{ duration: 1.5 }}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 2,
                        objectFit: 'cover'
                      }}
                    />

                    <video
                      muted
                      autoPlay
                      playsInline
                      preload="auto"
                      width="100%"
                      height="100%"
                      sx={{ objectFit: 'cover' }}
                      ref={el => (videoRef.current[page] = el)}
                    >
                      <source src={`/slider/${mp4}`} type="video/mp4" />
                      <source src={`/slider/${webm}`} type="video/webm" />
                    </video>
                  </Box>
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </motion.div>
        </AspectRatio>
      </Box>
      <Box variant="layout.grid2" sx={{ px: [0, 40] }}>
        <Box sx={{ pt: 20, display: ['none', 'block'] }}>
          <Text variant="h3">{text1}</Text>
        </Box>
        <Box>
          <Box
            sx={{
              mt: [20, 35],
              display: 'grid',
              gridTemplateColumns: '1fr auto'
            }}
          >
            <Box
              sx={{
                width: '7ch',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ overflow: 'hidden', position: 'relative' }} as="span">
                <Box sx={{ fontSize: 18, width: '2ch' }} />
                <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    key={page}
                    custom={direction}
                    variants={variants2}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    sx={{
                      fontSize: 18,
                      position: 'absolute',
                      top: 0,
                      left: 0
                    }}
                  >
                    {String(index + 1).padStart(2, 0)}
                  </motion.div>
                </AnimatePresence>
              </Box>
              <Box
                as="span"
                sx={{
                  fontSize: 18,
                  color: 'gray500',
                  width: '2ch',
                  textAlign: index === 0 ? 'left' : 'center'
                }}
              >
                —
              </Box>
              <Box
                as="span"
                sx={{
                  fontSize: 18,
                  color: 'gray500',
                  width: '2ch'
                }}
              >
                {String(slides.length).padStart(2, 0)}
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={prev}
                sx={{
                  mr: 20,
                  width: 'auto',
                  height: 'auto',
                  cursor: 'pointer',
                  color: 'gray500',
                  '&:hover': { color: 'text' }
                }}
              >
                <PrevIcon sx={{ width: 17, height: 17 }} />
              </IconButton>
              <IconButton
                onClick={next}
                sx={{
                  width: 'auto',
                  height: 'auto',
                  cursor: 'pointer',
                  color: 'gray500',
                  '&:hover': { color: 'text' }
                }}
              >
                <NextIcon sx={{ width: 17, height: 17 }} />
              </IconButton>
            </Box>
            <Box sx={{ mt: 20, pl: 1, display: ['none', 'block'] }}>
              <Text variant="text1">{text2}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: ['block', 'none'] }}>
        <Text variant="h3" sx={{ my: 10 }} as="div">
          {text1}
        </Text>
        <Text variant="text1">{text2}</Text>
      </Box>
    </Box>
  )
}

const variants1 = {
  center: { x: 0 },
  enter: d => ({ x: d > 0 ? '100%' : '-100%' }),
  exit: d => ({ x: d < 0 ? '100%' : '-100%' })
}

const variants2 = {
  center: { y: 0, scale: 1 },
  enter: d => ({ y: d > 0 ? '100%' : '-100%', scale: 0.25 }),
  exit: d => ({ y: d < 0 ? '100%' : '-100%', scale: 0.25 })
}

const transition = {
  x: {
    delay: 0.5,
    duration: 1.5,
    ease: [0.77, 0, 0.175, 1]
  }
}

const containerSx = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  position: 'absolute'
}

export default VideoSlider
