import Hr from "components/Hr";
import NextSection from "components/NextSection";
import ContentBuilder from "components/ContentBuilder";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useParams } from "react-router-dom";
import { Box, Text, AspectRatio, Image } from "theme-ui";
import { useProjectsById } from "store/projects";
import { safeJSONParse } from "lib/helpers";

const Rent = () => {
  const id = Number(useParams().id);

  const { data: project = {} } = useProjectsById(id);
  const content = safeJSONParse(project?.content) ?? [];
  const ratio = useResponsiveValue([280 / 158, 1000 / 562]);

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box>
        <Box>
          <Text variant="h1" as="h1">
            {project.name}
          </Text>
        </Box>
        <Box sx={{ mt: [12, 60], mb: [20, 40] }}>
          <Box sx={{ pl: [0, (360 * 100) / 1360 + "%"] }}>
            <Text
              as="div"
              variant="text4"
              sx={{ textAlign: ["left", "right"] }}
            >
              {project.address}
            </Text>
          </Box>
        </Box>
        {project.pictureUrl && (
          <Box sx={{ pl: [0, (360 * 100) / 1360 + "%"] }}>
            <AspectRatio ratio={ratio}>
              <Image
                src={project.pictureUrl}
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </AspectRatio>
          </Box>
        )}
        <Hr sx={{ mt: [40, 100], mb: [20, 50] }} />
        {project.content && (
          <ContentBuilder blocks={content} projectId={project.id} />
        )}
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="Аренда" href="/rent" />
      </Box>
    </Box>
  );
};

export default Rent;
