/** @jsxImportSource theme-ui */
import React from 'react'
import Hr from 'components/Hr'
import ExLink from 'components/ExLink'
import NextSection from 'components/NextSection'
import Collapse from 'components/Collapse'
import { usePolicy } from 'store/policy'
import { Box, Text, Link } from 'theme-ui'

const Soglasie = () => {
  const { data: policy } = usePolicy()

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Text variant="h3" as="div">
        ПОЛИТИКА в области обработки и защиты персональных данных в Обществе с
        ограниченной ответственностью Специализированный застройщик «ГРАНИТ»
      </Text>
      <Box sx={{ mt: [20, 40] }}>
        <ExLink href="/soglasie" size="small">
          Согласие на обработку персональных данных пользователя Сайта
        </ExLink>
      </Box>
      <Box sx={{ mt: [20, 40] }}>
        <Hr />
      </Box>
      {/* md */}
      <Box variant="layout.grid2" sx={{ display: ['none', 'grid'] }}>
        <Box>
          <Box
            sx={{
              top: 0,
              bottom: 0,
              position: 'sticky',
              minHeight: '100vh',
              color: 'gray500'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                pr: 100,
                py: 20
              }}
            >
              {policy.map((p, i) => (
                <Box key={i} sx={{ mb: 20 }}>
                  <Text
                    variant="text2"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { color: 'text' }
                    }}
                  >
                    <Link
                      href={`#section-${i}`}
                      sx={{ textDecoration: 'none' }}
                    >
                      {i + 1}. {p.name}
                    </Link>
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box sx={{ pt: 20 }}>
          {policy.map((p, i) => (
            <Box key={i}>
              {i > 0 && (
                <Text variant="text1" as="div">
                  &nbsp;
                </Text>
              )}
              <Text variant="text4" as="div" id={`section-${i}`}>
                {i + 1}. {p.name}
              </Text>
              <Text variant="text1" as="div">
                &nbsp;
              </Text>
              <Text variant="text1" as="div">
                <Box dangerouslySetInnerHTML={{ __html: p.text }} />
              </Text>
            </Box>
          ))}
          <Text variant="text1">{/*  */}</Text>
        </Box>
      </Box>
      {/* sm */}
      <Box sx={{ display: ['block', 'none'] }}>
        {policy.map((p, i) => {
          return (
            // prettier-ignore
            <Collapse key={i} name={<Text variant="text4">{i + 1}. {p.name}</Text>}>
              <Text variant="text5" as="div" sx={{ pb: 20 }}>
                <Box dangerouslySetInnerHTML={{ __html: p.text }} />
              </Text>
            </Collapse>
          )
        })}
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="проекты" href="/projects" />
      </Box>
    </Box>
  )
}

export default Soglasie
