import http from 'lib/http'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import { createResponse } from 'lib/helpers'

export const useNewsById = id => {
  const response = useSWR(`/news/${id}`)
  return createResponse(response)
}

export const useNewsFindAll = conditions => {
  const defaults = { count: 10, page: 1, ...conditions }
  const response = useSWR(`/news?count=${defaults.count}&page=${defaults.page}`)
  return createResponse(response)
}

export const useNewsPagination = conditions => {
  const defaults = { count: 10, ...conditions }

  const { data, size, setSize, error } = useSWRInfinite(
    (page, data) => {
      if (data && !data.data.length) return null
      return `/news?page=${page + 1}&count=${defaults.count}`
    },
    async query => await http(query)
  )

  const news = (data ?? []).reduce(
    (acc, cur) => {
      acc.list.push(...cur.data)
      acc.meta = cur.meta
      return acc
    },
    {
      list: [],
      meta: {}
    }
  )

  return {
    size,
    setSize,
    error,
    data: news,
    loading: !error && !data
  }
}
