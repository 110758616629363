const theme = {
  config: {
    initialColorModeName: 'dark',
    useColorSchemeMediaQuery: false
  },
  breakpoints: ['960px'],
  styles: {
    root: {
      overflowX: 'hidden',
      fontFamily: 'body',
      bg: 'primary',
      'select, [type=text], [type=password],textarea': {
        fontFamily: 'body'
      },
      '& *': {
        WebkitFontSmoothing: 'subpixel-antialiased'
      },
      '& a': {
        color: 'inherit',
        textDecorationSkip: 'objects',
        textDecorationSkipInk: 'none'
      }
    }
  },
  space: [],
  fonts: {
    body: '"Montserrat", sans-serif',
    heading: 'inherit',
    monospace: 'monospace'
  },
  colors: {
    primary: '#1A1D22',
    gray500: '#8B8D90',
    text: '#FDFEFF',
    secondary: '#F4E347',
    modes: {
      light: {
        primary: '#FDFEFF',
        gray500: '#8B8D90',
        text: '#1A1D22',
        secondary: '#F4E347'
      }
    }
  },
  layout: {
    container: {
      maxWidth: 1440,
      margin: 'auto',
      px: [20, 40]
    },
    grid2: {
      gap: [0, 68],
      display: 'grid',
      gridTemplateColumns: ['auto', '54.6% auto']
    }
  },
  text: {
    default: {},
    h1: {
      fontSize: [35, 90],
      lineHeight: ['initial', '110px'],
      textTransform: 'uppercase',
      fontWeight: 600
    },
    h2: {
      fontSize: [28, 50],
      textTransform: 'uppercase',
      fontWeight: 600
    },
    h3: {
      fontSize: [20, 40],
      textTransform: 'uppercase',
      fontWeight: 600
    },
    text1: {
      fontSize: [14, 18],
      fontWeight: 500
    },
    text2: {
      fontSize: [14, 16],
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    text3: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '150%',
      letterSpacing: '0.01em',
      textTransform: 'uppercase'
    },
    text4: {
      fontSize: [14, 25],
      textTransform: 'uppercase',
      fontWeight: [700, 600]
    },
    text5: {
      fontSize: [14, 16],
      textTransform: ['none', 'uppercase'],
      fontWeight: [500, 600]
    }
  }
}

export default theme
