import { createResponse } from 'lib/helpers'
export const usePolicy = () => {
  return createResponse({
    data: {
      data: [
        {
          name: `Общие положения`,
          text: `
Настоящая политика в области обработки и защиты персональных данных (далее - Политика):
<br /><br />разработана в целях обеспечения реализации требований законодательства РФ в области обработки персональных данных субъектов персональных данных;
<br /><br />раскрывает основные категории персональных данных, обрабатываемых в ООО СЗ «ГРАНИТ» (далее - Оператор), цели, способы и принципы обработки Оператором персональных данных, права и обязанности Оператора при обработке персональных данных, права субъектов персональных данных, а также перечень мер, применяемых Оператором в целях обеспечения безопасности персональных данных при их обработке в информационно-телекоммуникационной сети;
<br /><br />является общедоступным документом, декларирующим концептуальные основы деятельности Оператора при обработке персональных данных.
<br /><br />В настоящей Политике используются следующие понятия и термины:
<br /><br />Оператор - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
<br /><br />Обработка персональных данных - получение, хранение, комбинирование, передача или любое другое использование персональных данных.
<br /><br />ИСПДн (Информационная система персональных данных) – информационная система, представляющая собой совокупность персональных данных, содержащихся в базе данных, а также информационных технологий и технических средств, позволяющих осуществлять обработку таких персональных данных с использованием средств автоматизации или без использования таких средств.
<br /><br />Персональные данные - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).
<br /><br />Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
<br /><br />Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
<br /><br />Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;
<br /><br />Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;
<br /><br />Сайт – совокупность размещенных в сети Интернет веб-страниц, объединенных единой темой, дизайном и единым адресным пространством домена
<br /><br />Администрация Сайта (Администрация) – лица, уполномоченные Владельцем Сайта на осуществление управления Сайтом и иные действия, связанные с его использованием. Администрация Сайта действует от имени Владельца Сайта, если иное не будет указано отдельно.
          `
        },
        {
          name: `Правовые основания и цели обработки персональных данных`,
          text: `
2.1. Политика Оператора в области обработки персональных данных определяется в соответствии со следующими нормативными правовыми актами РФ:
<br /><br />Конституция Российской Федерации;
<br /><br />Гражданский кодекс Российской Федерации;
<br /><br />Постановление Правительства Российской Федерации от 01.11.2012 № 1119 «Об утверждении требований к защите персональных данных при обработке в информационных системах персональных данных»;
<br /><br />Положение об обработке персональных данных, осуществляемых без использования средств автоматизации, утверждённое постановлением Правительства Российской Федерации от 15.09.2008 № 687;
<br /><br />Федеральный закон РФ от 27.07.2006 № 152-ФЗ «О персональных данных»;
<br /><br />Федеральный закон от 27.07.2006 №149-ФЗ «Об информатизации, информационных технологиях и о защите информации».
<br /><br />2.2. Во исполнение настоящей Политики единоличным исполнительным органом Оператора издал следующие локальные правовые акты:
<br /><br />приказ о назначении ответственного лица за организацию обработки персональных данных;
<br /><br />приказ об утверждении Политики в области обработки и защиты персональных данных в информационно-телекоммуникационных сетях;
<br /><br />приказ об утверждении типовой формы согласия на обработку персональных данных.
<br /><br />2.3. Цели обработки персональных данных:
<br /><br />исполнение положений нормативных правовых актов, указанных в пункте 2.1 настоящей Политики;
<br /><br />регистрации сведений субъектов персональных данных, необходимых для осуществления деятельности Оператора;
<br /><br />предоставление информации рекламного характера;
<br /><br />проведение маркетинговых, статистических и иных исследований/опросов на основе общедоступных данных;
<br /><br />идентификация субъектов персональных данных для исполнения обязательств по заключенным договорам между субъектами персональных данных и Оператором, в том числе для оказания им технической поддержки;
<br /><br />для таргетинга рекламных и/или информационных материалов по возрасту, полу, другим признакам.
<br /><br />исполнение иных полномочий, возложенных на Оператора.
          `
        },
        {
          name: `Обрабатываемые категории персональных данных и источники их поступления`,
          text: `
3.1. В информационных системах персональных данных Оператора обрабатываются следующие категории персональных данных:
<br /><br />1) Персональные данные субъектов персональных данных, при обращении за услугой:
<br /><br />Фамилия, имя, отчество;
<br /><br />Дата и место рождения;
<br /><br />Адрес проживания;
<br /><br />Семейное положение;
<br /><br />Социальное положение;
<br /><br />Имущественное положение;
<br /><br />Доходы;
<br /><br />Паспортные данные;
<br /><br />Данные ИНН;
<br /><br />Данные Пенсионного страхового свидетельства;
<br /><br />Сведения о рождении детей, о заключении/расторжении брака;
<br /><br />Телефоны домашний и сотовый;
<br /><br />Сведения о трудовой деятельности;
<br /><br />Сведения о ближайших родственниках (фамилия имя отчество, дата рождения, степень родства);
<br /><br />дополнительные сведения, предусмотренные условиями договора и требованиями федеральных законов, определяющих случаи и особенности обработки персональных данных.
          `
        },
        {
          name: `Источники получения персональных данных`,
          text: `
4.1. Получение сведений о персональных данных осуществляется на основании документов и информации, представленных при заполнении формы обратной связи, регистрации на Сайте Оператора либо лично лицами, заключающими гражданско-правовые договоры с Оператором, граждан, обратившихся к Оператору в установленном порядке.
          `
        },
        {
          name: `Использование cookies`,
          text: `
5.1. На Сайте применяется технология идентификации, основанная на использовании файлов cookies.
<br /><br />5.2. При доступе субъекта персональных данных к Сайту на устройство, используемое им для доступа, могут быть записаны файлы cookies, которые в дальнейшем будут использованы для автоматической авторизации субъекта ПДн на Сайте, а также для сбора статистических данных, в частности о посещаемости Сайта.
<br /><br />5.3. Если субъект персональных данных полагает, что по тем или иным причинам использование технологии cookies для него неприемлемо, он вправе запретить сохранение файлов cookies на компьютере, используемом им для доступа к Сайту, соответствующим образом настроив браузер. При этом следует иметь в виду, что, отдельные сервисы, использующие данную технологию, могут оказаться недоступными.
          `
        },
        {
          name: `Основные принципы обработки, передачи и хранения персональных данных`,
          text: `
6.1. Оператор в своей деятельности обеспечивает соблюдение принципов обработки персональных данных, указанных в статье 5 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
<br /><br />6.2. Оператор не осуществляет трансграничную передачу персональных данных (передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу).
<br /><br />6.3. Оператором используются общедоступные источники персональных данных (справочник, официальный информационный Сайт). Персональные данные, сообщаемые субъектом персональных данных (фамилия, имя, отчество, абонентский номер и иные необходимые сведения) включаются в такие источники только с письменного согласия субъекта персональных данных.

          `
        },
        {
          name: `Сведения о лицах, осуществляющих обработку персональных данных`,
          text: `
7.1. В целях соблюдения законодательства РФ, для достижения целей обработки, а также в интересах и с согласия субъектов персональных данных Оператор в ходе своей деятельности осуществляет обработку персональных данных лично либо поручает другим лицам. В частности, хостинг информационной системы Сайта Оператора осуществляется компанией ООО "Регистратор доменных имен РЕГ.РУ" (ОГРН 1067746613494). Также обработка персональных данных субъекта персональных данных может осуществляться Индивидуальным предпринимателем Насыртдиновым Р.И. (ОГРНИП: 320631300116878). Оператор гарантирует, что обработка персональных данных субъекта персональных данных такими лицами будет осуществляться строго в соответствии с положениями настоящей Политики обработки данных.
<br /><br />Кроме вышеуказанных лиц Оператор может предоставлять персональные данные следующим лицам:
<br /><br />контролирующим органам государственной власти и местного самоуправления;
<br /><br />кредитным организациям.
<br /><br />Передача Персональных данных третьим лицам возможна только при письменном согласии субъекта персональных данных. Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Обработка персональных данных в случаях, не предусмотренных законодательством, либо обработка персональных данных несовместимая с целями сбора персональных данных, не допускается.
          `
        },
        {
          name: `Меры по обеспечению безопасности персональных данных при их обработке`,
          text: `
8.1. Оператор при обработке персональных данных принимает все необходимые правовые, организационные и технические меры для защиты и сохранности персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.
<br /><br />8.2. Обеспечение безопасности персональных данных достигается, в частности:
<br /><br />назначением ответственного за организацию обработки персональных данных;
<br /><br />осуществлением внутреннего контроля соответствия обработки персональных данных Федеральному закону от 27.07.2006 № 152-ФЗ «О персональных данных» и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, локальным актам;
<br /><br />ознакомлением сотрудников Оператора, непосредственно осуществляющих обработку персональных данных, с положениями законодательства Российской Федерации о персональных данных, в том числе требованиями к защите персональных данных, локальными актами в отношении обработки персональных данных, и (или) обучением указанных работников;
<br /><br />применением организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных, необходимых для выполнения требований к защите персональных данных;
<br /><br />обнаружением фактов несанкционированного доступа к персональным данным и принятием мер;
<br /><br />восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
<br /><br />контролем за принимаемыми мерами по обеспечению безопасности персональных данных.
<br /><br />8.3. В соответствии с требованиями №152-ФЗ «О персональных данных» ответственным лицом за организацию обработки персональных данных назначен: единоличный исполнительный орган Оператора.
          `
        },
        {
          name: `Права субъектов персональных данных`,
          text: `
9.1. Субъект персональных данных имеет право на получение сведений об обработке его персональных данных Оператором.
<br /><br />9.2. Субъект персональных данных вправе требовать от Оператора уточнения, подтверждения факта обработки обрабатываемых Оператором персональных данных субъекта персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
<br /><br />9.3. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами, в том числе если:
<br /><br />обработка персональных данных, включая персональные данные, полученные в результате оперативно-розыскной, контрразведывательной и разведывательной деятельности, осуществляется в целях обороны страны, безопасности государства и охраны правопорядка;
<br /><br />обработка персональных данных осуществляется органами, осуществившими задержание субъекта персональных данных по подозрению в совершении преступления, либо предъявившими субъекту персональных данных обвинение по уголовному делу, либо применившими к субъекту персональных данных меру пресечения до предъявления обвинения, за исключением предусмотренных уголовно-процессуальным законодательством Российской Федерации случаев, если допускается ознакомление подозреваемого или обвиняемого с такими персональными данными;
<br /><br />обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма;
<br /><br />доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.
<br /><br />9.4. Для реализации своих прав и защиты законных интересов субъект персональных данных имеет право обратиться к Оператору. Оператор рассматривает любые обращения и жалобы со стороны субъектов персональных данных, тщательно расследует факты нарушений и принимает все необходимые меры для их немедленного устранения, наказания виновных лиц и урегулирования спорных и конфликтных ситуаций в досудебном порядке.
<br /><br />9.5. Субъект персональных данных вправе обжаловать действия или бездействие Оператора путем обращения в уполномоченный орган по защите прав субъектов персональных данных.
<br /><br />9.6. Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.

          `
        },
        {
          name: `Сроки обработки (хранения) персональных данных`,
          text: `
10.1. Течение срока обработки персональных данных начинается с момента их получения Оператором.10.2. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъект персональных данных, не дольше, чем того требуют цели их обработки.
<br /><br />10.3. Персональные данные субъектов персональных данных, обратившихся к Оператору в установленном порядке, хранятся в делах структурных подразделений Оператора в течение срока, определённого законодательством и номенклатурой дел Оператора (5 лет).
          `
        },
        {
          name: `Уточнение, блокирование и уничтожение персональных данных`,
          text: `
11.1. Целью уточнения персональных данных, в том числе обновления и изменения, является обеспечение достоверности, полноты и актуальности персональных данных, обрабатываемых Оператором.
<br /><br />11.2. Уточнение персональных данных осуществляется Оператором по собственной инициативе, по требованию субъекта персональных данных или его представителя, по требованию уполномоченного органа по защите прав субъектов персональных данных в случаях, когда установлено, что персональные данные являются неполными, устаревшими, недостоверными.
<br /><br />11.3. Целью блокирования персональных данных является временное прекращение обработки персональных данных до момента устранения обстоятельств, послуживших основанием для блокирования персональных данных.
<br /><br />11.4. Блокирование персональных данных осуществляется Оператором по требованию субъекта персональных данных или его представителя, а также по требованию уполномоченного органа по защите прав субъектов персональных данных в случае выявления недостоверных персональных данных или неправомерных действий с ними.
<br /><br />11.5. Уничтожение персональных данных осуществляется Оператором:
<br /><br />по достижении цели обработки персональных данных;
<br /><br />в случае утраты необходимости в достижении целей обработки персональных данных;
<br /><br />в случае отзыва субъектом персональных данных согласия на обработку своих персональных данных;
<br /><br />по истечению срока хранения;
<br /><br />по требованию субъекта персональных данных или уполномоченного органа по защите прав субъектов персональных данных в случае выявления фактов совершения администрацией района неправомерных действий с персональными данными, когда устранить соответствующие нарушения не представляется возможным.
<br /><br />При уничтожении материальных носителей персональных данных составляется акт об уничтожении носителей, содержащих персональные данные
          `
        },
        {
          name: `Заключительные положения`,
          text: `
12.1. Настоящая Политика является внутренним документом Оператора, общедоступной и подлежит размещению на официальном Сайте Оператора.
<br /><br />12.2. Настоящая Политика подлежит изменению, дополнению в случае появления новых законодательных актов и специальных нормативных документов по обработке и защите персональных данных, но не чаще одного раза в три года.
<br /><br />12.3. Контроль исполнения требований настоящей Политики осуществляется ответственным за обеспечение безопасности персональных данных.
<br /><br />12.4. Ответственность должностных лиц Оператора, имеющих доступ к персональным данным, за невыполнение требований норм, регулирующих обработку и защиту персональных данных, определяется в соответствии с законодательством Российской Федерации и внутренними документами Оператора.
          `
        }
      ]
    }
  })
}
