import RouterLink from "components/RouterLink";
import { Box, Text } from "theme-ui";

const routes = [
  {
    path: "/",
    exact: true,
    Component: require("./Home").default,
  },
  {
    path: "/company/:id?",
    exact: true,
    Component: require("./Company").default,
  },
  {
    path: "/projects",
    exact: true,
    Component: require("./ProjectList").default,
  },
  {
    path: "/projects/:id",
    exact: true,
    Component: require("./Project").default,
  },
  {
    path: "/gallery/:resourceName/:id/:hash",
    exact: true,
    Component: require("./ProjectGallery").default,
  },
  {
    path: "/rent",
    exact: true,
    Component: require("./RentList").default,
  },
  {
    path: "/rent/:id",
    exact: true,
    Component: require("./Rent").default,
  },
  {
    path: "/news",
    exact: true,
    Component: require("./NewsList").default,
  },
  {
    path: "/news/:id",
    exact: true,
    Component: require("./News").default,
  },
  {
    path: "/documents",
    exact: true,
    Component: require("./Documents").default,
  },
  {
    path: "/contacts",
    exact: true,
    Component: require("./Contacts").default,
  },
  {
    path: "/personaltop",
    exact: true,
    Component: require("./Personaltop").default,
  },
  {
    path: "/soglasie",
    exact: true,
    Component: require("./Soglasie").default,
  },
  {
    path: "*",
    Component: () => (
      <Box variant="layout.container" py={50}>
        <Box>
          <Text variant="h3" as="div" mb={6}>
            Страница не найдена
          </Text>
          <RouterLink href="/" sx={{ color: "blue500" }}>
            <Text variant="text4">Вернуться на главную</Text>
          </RouterLink>
        </Box>
      </Box>
    ),
  },
];

export default routes;
