/** @jsxImportSource theme-ui */
import React from 'react'
import ExLink from 'components/ExLink'
import { Box, AspectRatio, Image } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'

const NextSection = props => {
  if (!props.name || !props.href) {
    return <Placeholder />
  }
  return <HrefSection {...props} />
}

const HrefSection = ({ name, href }) => {
  const images = IMAGES.sort(() => Math.random() - 0.5)
  return (
    <Box>
      <ExLink href={href} size="large">
        {name}
      </ExLink>
      <Box sx={{ mt: [20, 40] }}>
        <Slider images={images} />
      </Box>
    </Box>
  )
}

const Placeholder = () => {
  const images = IMAGES.sort(() => Math.random() - 0.5)
  return <Slider images={images} />
}

const Slider = ({ images }) => {
  const [index, _setIndex] = React.useState(0)

  const setIndex = ev => {
    const chunk = window.innerWidth / images.length
    _setIndex(Math.ceil(ev.pageX / chunk) - 1)
  }

  return (
    <Box onMouseMove={setIndex}>
      <Box sx={{ width: 0, height: 0, overflow: 'hidden' }}>
        {images.map(src => (
          <Image src={src} key={src} />
        ))}
      </Box>
      <AspectRatio ratio={useResponsiveValue([280 / 200, 1360 / 700])}>
        <motion.div
          whileInView={{ scale: 1, opacity: 1 }}
          initial={{ scale: 0.6, opacity: 0 }}
          sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
          transition={{
            opacity: {
              delay: 0.1,
              duration: 1,
              ease: [0.165, 0.84, 0.44, 1]
            },
            scale: {
              duration: 1.5,
              ease: [0.165, 0.84, 0.44, 1]
            }
          }}
        >
          <motion.div
            whileInView={{ scale: 1 }}
            initial={{ scale: 1.2 }}
            sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
            transition={{
              delay: 0.1,
              scale: {
                duration: 1.5,
                ease: [0.165, 0.84, 0.44, 1]
              }
            }}
          >
            <AnimatePresence>
              <motion.img
                key={images[index]}
                src={images[index]}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 1,
                  ease: [0.165, 0.84, 0.44, 1]
                }}
                sx={{
                  display: 'block',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  left: 0,
                  top: 0
                }}
              />
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </AspectRatio>
    </Box>
  )
}

const IMAGES = [
  require('images/sl1.jpg').default,
  require('images/sl2.jpg').default,
  require('images/sl3.jpg').default
]

export default NextSection
