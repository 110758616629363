/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui'
import { useColorMode } from 'theme-ui'
import { motion } from 'framer-motion/dist/framer-motion'

const ToggleSwitch = ({
  switchOn,
  onText,
  offText,
  onSwitchOff,
  onSwitchOn
}) => {
  const [colorMode] = useColorMode()
  return (
    <Box
      sx={{
        height: 25,
        borderRadius: 50,
        display: 'inline-flex',
        border: 'solid 1px',
        position: 'relative',
        borderColor: colorMode === 'light' ? '#8b8d90' : 'white'
      }}
    >
      <Box
        onClick={onSwitchOff}
        sx={{
          cursor: 'pointer',
          pl: 13,
          pr: 10,
          pt: 4,
          position: 'relative',
          zIndex: 2
        }}
      >
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 500,
            position: 'relative',
            zIndex: 2,
            color: switchOn ? '#8b8d90' : 'white'
          }}
        >
          {offText}
        </Box>
      </Box>
      <Box
        onClick={onSwitchOn}
        sx={{
          cursor: 'pointer',
          pl: 13,
          pr: 10,
          pt: 4,
          position: 'relative',
          zIndex: 2
        }}
      >
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 500,
            position: 'relative',
            zIndex: 2,
            color: switchOn ? 'white' : '#8b8d90'
          }}
        >
          {onText}
        </Box>
      </Box>
      <motion.div
        animate={{ left: switchOn ? '50%' : 0 }}
        sx={{ position: 'absolute', left: 0, width: '50%', top: 0 }}
      >
        <Slider />
      </motion.div>
    </Box>
  )
}

const Slider = () => {
  return (
    <Box
      sx={{
        height: 19,
        bg: '#8B8D90',
        borderRadius: 40,
        position: 'absolute',
        top: 2,
        left: 2,
        right: 2
      }}
    />
  )
}

export default ToggleSwitch
