/** @jsxImportSource theme-ui */
import { motion } from "framer-motion/dist/framer-motion";
import { AspectRatio } from "theme-ui";

const AnimatedImage = ({ src, ratio, animateHover = false }) => {
  return (
    <AspectRatio ratio={ratio}>
      <motion.div
        whileInView={{ scale: 1, opacity: 1 }}
        initial={{ scale: 0.6, opacity: 0 }}
        sx={{ width: "100%", height: "100%", overflow: "hidden" }}
        transition={{
          opacity: {
            delay: 0.1,
            duration: 1,
            ease: [0.165, 0.84, 0.44, 1],
          },
          scale: {
            duration: 1.5,
            ease: [0.165, 0.84, 0.44, 1],
          },
        }}
      >
        <motion.div
          whileInView={{ scale: 1 }}
          initial={{ scale: 1.2 }}
          sx={{ width: "100%", height: "100%", overflow: "hidden" }}
          transition={{
            delay: 0.1,
            scale: {
              duration: 1.5,
              ease: [0.165, 0.84, 0.44, 1],
            },
          }}
        >
          <motion.div
            whileHover={{ scale: animateHover ? 1.05 : 1 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          >
            <motion.img
              src={src}
              initial={{ filter: "grayscale(1)" }}
              whileInView={{ filter: "grayscale(0)" }}
              transition={{
                delay: 0.1,
                filter: {
                  duration: 2,
                  ease: [0, 0.55, 0.45, 1],
                },
              }}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transform: "scale(1.3)",
              }}
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </AspectRatio>
  );
};

export default AnimatedImage;
