import SliderMd from "components/Slider.md";
import SliderSm from "components/Slider.sm";
import AnimatedImage from "components/AnimatedImage";
import { useResponsiveValue } from "@theme-ui/match-media";
import { Box, Grid, Text, Link } from "theme-ui";

const Gallery = ({ meta, value, projectId }) => {
  const { type } = meta.variant;

  const Component = components.get(type);
  const props = createProps(type, value);
  return Component ? (
    <Box mb={[100, 80]}>
      <Component value={props} projectId={projectId} />
    </Box>
  ) : null;
};

const SingleImage = ({ value }) => {
  const ratio = useResponsiveValue([280 / 158, 1000 / 562]);
  return value ? (
    <Box sx={{ pl: [0, (360 * 100) / 1360 + "%"] }}>
      <AnimatedImage ratio={ratio} src={value.url} animateHover={true} />
    </Box>
  ) : null;
};

const ImageGrid = ({ value, projectId }) => {
  return value ? (
    <Grid gap={[20, 40]} columns={["1fr", "1fr 1fr 1fr 1fr"]}>
      {value.map((image, index) => (
        <Link
          href={`/gallery/projects/${projectId}/${image.hash}`}
          key={index}
          sx={{ textDecoration: "none" }}
        >
          <AnimatedImage ratio={1} src={image.url} animateHover={true} />
          {image.name && (
            <Box
              py={[10, 20]}
              sx={{ borderBottom: "1px solid", borderColor: "gray500" }}
            >
              <Text variant="text1" sx={{ textTransform: "uppercase" }}>
                {image.name}
              </Text>
            </Box>
          )}
        </Link>
      ))}
    </Grid>
  ) : null;
};

const DoubleImage = ({ value, projectId }) => {
  return value ? (
    <Grid
      columns={["1fr", "1fr 1fr"]}
      sx={{ rowGap: [20, 60], columnGap: [20, 40] }}
    >
      {value.map((image, index) => (
        <Link
          href={`/gallery/projects/${projectId}/${image.hash}`}
          key={index}
          sx={{ textDecoration: "none" }}
        >
          <AnimatedImage src={image.url} animateHover={true} />
          {image.name && (
            <Box
              py={[10, 20]}
              sx={{ borderBottom: "1px solid", borderColor: "gray500" }}
            >
              <Text variant="text1" sx={{ textTransform: "uppercase" }}>
                {image.name}
              </Text>
            </Box>
          )}
        </Link>
      ))}
    </Grid>
  ) : null;
};

const Slider = ({ value, projectId }) => {
  return value ? (
    <Box>
      <Box sx={{ display: ["none", "block"] }}>
        <SliderMd slides={value} projectId={projectId} />
      </Box>
      <Box sx={{ display: ["block", "none"] }}>
        <SliderSm slides={value} />
      </Box>
    </Box>
  ) : null;
};

const components = new Map([
  ["double", DoubleImage],
  ["single", SingleImage],
  ["tiles", ImageGrid],
  ["slider", Slider],
]);

const createProps = (type, value) => {
  return {
    single: value[0],
    double: value.slice(0, 2),
    tiles: value,
    slider: value,
  }[type];
};

export default Gallery;
