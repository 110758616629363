import { Box, Text, Input, Textarea } from 'theme-ui'

const TextField = ({ multiline, error, ...props }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {multiline && <Textarea sx={{ ...textFieldSx }} {...props} />}
      {!multiline && <Input sx={{ ...textFieldSx }} {...props} />}
      {error && <Text sx={{ color: 'secondary', fontSize: 12 }}>{error}</Text>}
    </Box>
  )
}

const textFieldSx = {
  boxShadow: 'none',
  borderRadius: 0,
  border: 0,
  fontSize: [14, 16],
  fontWeight: [500, 600],
  textTransform: 'uppercase',
  color: 'text',
  outline: 'none',
  py: [10, 24],
  borderBottom: 'solid 1px',
  borderColor: 'gray500',
  '&:focus::placeholder': {
    color: 'text'
  }
}

export default TextField
