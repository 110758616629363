import React from 'react'
import ExLink from 'components/ExLink'
import RouterLink from 'components/RouterLink'
import TextField from 'components/TextField'
import PhoneField from 'components/PhoneField'
import useForm from 'hooks/form'
import { Box, Text } from 'theme-ui'
import { pickBy } from 'lodash'

const getModel = () => ({
  name: '',
  phone: '',
  email: '',
  comment: ''
})

const WriteUsForm = () => {
  const form = useForm({ url: '/feedback' })
  const [state, setState] = React.useState(getModel())

  const setVal = (key, val) => setState(prev => ({ ...prev, [key]: val }))
  const submit = () => {
    const params = pickBy(state)
    form.submit({ ...params, requestType: 'callback' })
  }

  React.useEffect(() => {
    if (form.response) {
      setState(getModel())
    }
  }, [form.response])

  return (
    <Box>
      <Box sx={{ mb: [10, 16] }}>
        <TextField
          placeholder="ФИО"
          variant="outlined"
          value={state.name}
          error={form.validation.name}
          onChange={ev => setVal('name', ev.target.value)}
        />
      </Box>
      <Box sx={{ mb: [10, 16] }}>
        <PhoneField
          variant="outlined"
          placeholder="Телефон"
          value={state.phone}
          error={form.validation.phone}
          onChange={ev => setVal('phone', ev.target.value)}
        />
      </Box>
      <Box sx={{ mb: [10, 16] }}>
        <TextField
          variant="outlined"
          placeholder="E-mail"
          value={state.email}
          error={form.validation.email}
          onChange={ev => setVal('email', ev.target.value)}
        />
      </Box>
      <Box sx={{ mb: [20, 40] }}>
        <TextField
          multiline
          variant="outlined"
          placeholder="Сообщение"
          value={state.comment}
          error={form.validation.comment}
          onChange={ev => setVal('comment', ev.target.value)}
        />
      </Box>
      <Box>
        <Text variant="text1" sx={{ color: 'gray500' }}>
          Нажимая кнопку «Отправить» вы подтверждаете свое согласие на{' '}
          <RouterLink href="/soglasie" sx={{ textDecoration: 'underline' }}>
            обработку персональных данных
          </RouterLink>
        </Text>
      </Box>
      <Box mt={[20, 34]}>
        <ExLink
          href="#"
          size="small"
          onClick={ev => {
            ev.preventDefault()
            if (!form.loading) {
              submit()
            }
          }}
        >
          Отправить
        </ExLink>
      </Box>
      {form.response && (
        <Box sx={{ mt: 24, color: 'secondary' }}>
          Спасибо, Ваше сообщение отправлено.
        </Box>
      )}
    </Box>
  )
}

export default WriteUsForm
