/** @jsxImportSource theme-ui */
import React from 'react'
import ExLink from 'components/ExLink'
import Hr from 'components/Hr'
import RouterLink from 'components/RouterLink'
import { ReactComponent as LogoIcon } from 'icons/logo.svg'
import { ReactComponent as BurgerIcon } from 'icons/burger.svg'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { Box, Text } from 'theme-ui'

const HeaderSm = () => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'visible'
  }, [open])

  return (
    <Box>
      <Box sx={{ height: 56 }} />
      <Box
        sx={{
          zIndex: 101,
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bg: 'primary'
        }}
      >
        <Box sx={{ pt: 20, pl: 20, color: 'text' }}>
          <RouterLink href="/">
            <LogoIcon sx={{ width: 120, height: 36 }} />
          </RouterLink>
        </Box>
        <Box sx={{ pt: 30, pr: 14 }}>
          {!open && (
            <BurgerIcon
              sx={{ width: 33, height: 33 }}
              onClick={() => setOpen(true)}
            />
          )}
          {open && (
            <CloseIcon
              sx={{ width: 33, height: 33 }}
              onClick={() => setOpen(false)}
            />
          )}
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'fixed',
            bg: 'primary',
            zIndex: 100,
            pt: 156,
            px: 20,
            pb: 50,
            overflowY: 'auto'
          }}
        >
          <Box>
            <RouterLink href="/company" sx={linkSx} activeClassName="selected">
              Компания
            </RouterLink>
            <RouterLink href="/projects" sx={linkSx} activeClassName="selected">
              Проекты
            </RouterLink>
            <RouterLink href="/rent" sx={linkSx} activeClassName="selected">
              Аренда
            </RouterLink>
            <RouterLink href="/news" sx={linkSx} activeClassName="selected">
              Новости
            </RouterLink>
            <RouterLink
              href="/documents"
              sx={linkSx}
              activeClassName="selected"
            >
              Документы
            </RouterLink>
            <RouterLink href="/contacts" sx={linkSx} activeClassName="selected">
              Контакты
            </RouterLink>
          </Box>
          <Hr sx={{ mt: 50, mb: 20 }} />
          <Box>
            <ExLink size="small" href="tel:+ 7 (8482) 312-000">
              + 7 (8482) 312-000
            </ExLink>
          </Box>
          <Box sx={{ mt: 20 }}>
            <ExLink size="small" href="mailto:sales@granirf.ru">
              sales@granirf.ru
            </ExLink>
          </Box>
          <Box sx={{ mt: 20 }}>
            <Text variant="text5" sx={{ color: 'gray500' }}>
              Адрес
            </Text>
          </Box>
          <Box sx={{ mt: 10 }}>
            <Text variant="text1">
              445044, Самарская обл. г. Тольятти, Южное шоссе, д. 103
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const linkSx = {
  display: 'block',
  fontSize: '28px',
  textTransform: 'uppercase',
  color: 'text',
  fontWeight: 600,
  textDecoration: 'none',
  '&.selected': {
    color: 'gray500',
    textDecoration: 'none'
  },
  '&+&': {
    mt: 20
  },
  '&:hover': {
    textDecoration: 'none'
  }
}

export default HeaderSm
