import useSWR from "swr";
import { createResponse } from "lib/helpers";

export const useProjects = () => {
  const response = useSWR("/projects?group_alias=projects");
  return createResponse(response);
};

export const useProjectsById = (id) => {
  const response = useSWR(`/projects/${id}`);
  return createResponse(response);
};
