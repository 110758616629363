/** @jsxImportSource theme-ui */
import React from 'react'
import { Box } from 'theme-ui'
import { ReactComponent as AdIcon } from 'icons/ad.svg'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'

const Collapse = ({ open: iOpen, children, name }) => {
  const [open, setOpen] = React.useState(iOpen)
  const toggle = () => setOpen(o => !o)

  const [hover, setHover] = React.useState(false)
  const enter = () => setHover(true)
  const leave = () => setHover(false)

  return (
    <Box onMouseEnter={enter} onMouseLeave={leave}>
      <Box
        onClick={toggle}
        sx={{
          pt: [10, 40],
          pb: [20, 40],
          minHeight: 56,
          cursor: 'pointer',
          userSelect: 'none',
          position: 'relative',
          '&:hover': {
            textDecoration: 'underline',
            textDecorationThickness: '0.2em'
          }
        }}
      >
        <Box sx={{ pr: 40 }}>{name}</Box>
        <Box sx={{ position: 'absolute', right: 0, top: [16, 40] }}>
          <motion.div
            animate={{ rotate: open ? -90 : 0 }}
            transition={{
              duration: 0.9,
              ease: [0.77, 0, 0.175, 1]
            }}
          >
            <AdIcon sx={{ width: [22, 34], height: [22, 34] }} />
          </motion.div>
        </Box>
      </Box>
      <AnimatePresence>
        {open && (
          <motion.div
            exit={{ height: 0 }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            style={{ overflow: 'hidden', cursor: 'default' }}
            transition={{
              ease: [0.77, 0, 0.175, 1],
              duration: 0.9
            }}
          >
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 0.6,
                ease: [0.77, 0, 0.175, 1]
              }}
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <Box sx={{ height: 1, bg: 'text', opacity: hover ? 1 : 0.0 }} />
      <Box sx={{ height: 1, bg: 'text', opacity: hover ? 1 : 0.5 }} />
    </Box>
  )
}

export default Collapse
