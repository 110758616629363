import { Box } from 'theme-ui'

const styles = {
  '&': {
    img: {},
    a: {
      color: 'inherit'
    },
    'p:first-of-type': {
      mt: 0
    }
  }
}

const Html = ({ source, sx, ...props }) => (
  <Box
    sx={{ ...styles, ...sx }}
    dangerouslySetInnerHTML={{ __html: source }}
    {...props}
  />
)

export default Html
