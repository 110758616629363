/** @jsxImportSource theme-ui */
import RouterLink from 'components/RouterLink'
import Hr from 'components/Hr'
import ExLink from 'components/ExLink'
import NextSection from 'components/NextSection'
import { humanDate } from 'lib/helpers'
import { Box, Text, Image, AspectRatio } from 'theme-ui'
import { motion } from 'framer-motion/dist/framer-motion'
import { useNewsPagination } from 'store/news'

const NewsList = () => {
  const { data: news, size, setSize } = useNewsPagination({ count: 10 })
  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box sx={{ mb: [20, 40] }}>
        <Text variant="h1" as="h1">
          Новости
        </Text>
      </Box>
      <Hr sx={{ mb: [30, 40] }} />
      <Box>
        {news.list.map((news, index, arr) => (
          <motion.div key={news.id} whileHover="enter">
            <RouterLink
              href={`/news/${news.id}`}
              sx={{ ':hover': { textDecoration: 'none' } }}
            >
              <Box variant="layout.grid2">
                <Box>
                  <Text variant="h3" as="h2">
                    {news.name}
                  </Text>
                  <Text
                    variant="text1"
                    as="div"
                    sx={{ mt: [10, 40], mb: [10, 16] }}
                  >
                    {news.annotation}
                  </Text>
                  <Text variant="text2" as="div" sx={{ color: 'gray500' }}>
                    {humanDate(news.publishedAt)}
                  </Text>
                </Box>
                <Box sx={{ mt: [20, 0] }}>
                  <AspectRatio ratio={587 / 332}>
                    <motion.div
                      transition={imageTransition}
                      variants={imageVariants}
                      sx={{ width: '100%', height: '100%' }}
                    >
                      <Image
                        src={news.pictureUrl}
                        sx={{
                          display: 'block',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </motion.div>
                  </AspectRatio>
                </Box>
              </Box>
            </RouterLink>
            {index < arr.length - 1 && (
              <Hr sx={{ mt: [60, 40], mb: [30, 40] }} />
            )}
          </motion.div>
        ))}
        {size < news.meta.totalPages && (
          <Box
            sx={{
              mt: [40, 80],
              display: 'flex',
              justifyContent: 'center',
              '& svg': { transform: 'rotate(90deg)' }
            }}
          >
            <ExLink
              onClick={ev => {
                ev.preventDefault()
                setSize(size + 1)
              }}
              size="small"
              href="#"
            >
              ПОКАЗАТЬ еЩЕ
            </ExLink>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="Проекты" href="/projects" />
      </Box>
    </Box>
  )
}

const imageVariants = {
  enter: { scale: 1.05 }
}

const imageTransition = {
  ease: [0, 0.55, 0.45, 1],
  duration: 0.6
}

export default NewsList
