import React from 'react'
import TextField from 'components/TextField'
import NumberFormat from 'react-number-format'

const PhoneField = props => {
  const [onFocus, setOnFocus] = React.useState(false)
  return (
    <NumberFormat
      customInput={TextField}
      InputLabelProps={{ shrink: true }}
      format={`+7 (###) ###-##-##`}
      allowEmptyFormatting={onFocus}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      {...props}
    />
  )
}

export default PhoneField
