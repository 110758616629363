import { useResponsiveValue } from "@theme-ui/match-media";
import { Box, AspectRatio } from "theme-ui";

const Video = ({ value }) => {
  const ratio = useResponsiveValue([280 / 347, 1000 / 562]);
  return (
    <Box sx={{ pl: [0, (360 * 100) / 1360 + "%"], mb: [100, 80] }}>
      <AspectRatio ratio={ratio}>
        <iframe
          width="100%"
          height="auto"
          src={`https://www.youtube.com/embed/${value}`}
          frameBorder="0"
          title={value}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            top: 0,
            left: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
      </AspectRatio>
    </Box>
  );
};
export default Video;
