import Hr from "components/Hr";
import ExLink from "components/ExLink";
import NextSection from "components/NextSection";
import ContentBuilder from "components/ContentBuilder";
import { Box, Text, Image, AspectRatio } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useParams } from "react-router-dom";
import { useProjectsById } from "store/projects";
import { safeJSONParse } from "lib/helpers";

const Project = () => {
  const { data: project = {} } = useProjectsById(useParams().id);
  const content = safeJSONParse(project?.content) ?? [];

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      {project.name && (
        <Text variant="h1" as="h1">
          {project.name}
        </Text>
      )}
      {project.annotation && (
        <Box sx={{ mt: [10, 40] }} variant="layout.grid2">
          <Text variant="text1">{project.annotation}</Text>
        </Box>
      )}
      <Box sx={{ mt: [20, 80], mb: [20, 40] }}>
        <AspectRatio ratio={useResponsiveValue([282 / 158, 1360 / 300])}>
          <Image
            src={project.pictureUrl}
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </AspectRatio>
      </Box>
      <Box>
        {project.address && (
          <Text variant="text4" as="div" sx={{ mb: [10, 20] }}>
            {project.address}
          </Text>
        )}
        {project.link && (
          <ExLink size="medium" href={project.link} target="_blank">
            ПЕРЕЙТИ НА САЙТ
          </ExLink>
        )}
      </Box>
      <Hr sx={{ mt: [60, 40], mb: [20, 50] }} />
      {project.content && (
        <ContentBuilder blocks={content} projectId={project.id} />
      )}
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="проекты" href="/projects" />
      </Box>
    </Box>
  );
};

export default Project;
