/** @jsxImportSource theme-ui */
import React from 'react'
import Hr from 'components/Hr'
import ExLink from 'components/ExLink'
import Collapse from 'components/Collapse'
import NextSection from 'components/NextSection'
import AnimatedImage from 'components/AnimatedImage'
import { useParams } from 'react-router-dom'
import { Box, Text } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import { useBreakpointIndex } from '@theme-ui/match-media'
import * as Typo from 'components/Typo.atoms'

const Company = () => {
  const bIndex = useBreakpointIndex()

  const id = Number(useParams().id) || null
  React.useEffect(() => {
    if (id) {
      const timer = setTimeout(() => {
        const el = document.querySelector(`[data-company-id="${id}"]`)
        el?.scrollIntoView({
          behavior: 'smooth',
          block: bIndex > 0 ? 'start' : 'center'
        })
      }, 100)
      return () => clearTimeout(timer)
    }
  }, [id, bIndex])

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Text variant="h1" as="h1" sx={{ mb: [20, 40] }}>
        О компании
      </Text>
      <AnimatedImage
        ratio={useResponsiveValue([1, 1360 / 600])}
        src={useResponsiveValue([
          require('images/i9.jpg').default,
          require('images/i6.jpg').default
        ])}
      />
      <Box sx={{ mt: [100, 200] }}>
        <Box variant="layout.grid2">
          <Box>
            <Text variant="h2">ПОЗИЦИОНИ&shy;РОВАНИЕ</Text>
          </Box>
          <Box sx={{ pt: [20, 5] }}>
            <Text variant="text4" as="div">
              Понимаем, что на самом деле нужно клиенту
            </Text>
            <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
              Сейчас трудно представить жилое пространство без детских и
              активити-зон, но многим этого уже недостаточно. Появляются запросы
              на современное и качественное благоустройство территории, гостевые
              лобби, помещения для хранения колясок и велосипедов, коворкинги, и
              мы их реализуем.
            </Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text4" as="div">
              Знаем принципы разработки современных проектов
            </Text>
            <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
              Используем только выверенные инженерные и дизайн-решения.
            </Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text4" as="div">
              Умеем раскрывать потенциал объекта в рамках экономической модели
            </Text>
            <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
              И делаем это так, что актуальность проекта не теряется за время
              его реализации.
            </Text>
            <Hr sx={{ mt: 20 }} />
          </Box>
        </Box>
        <Box sx={{ pr: [0, (360 * 100) / 1360 + '%'], mt: [40, 80] }}>
          <AnimatedImage
            ratio={1000 / 700}
            src={require('images/i7.jpg').default}
          />
        </Box>
        <Box variant="layout.grid2" sx={{ mt: [100, 200] }}>
          <Box>
            <Text variant="h2">КОМПЕТЕНЦИИ</Text>
          </Box>
          <Box sx={{ mt: [20, 10] }}>
            <Text variant="text1">Аналитика локаций для будущих проектов</Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">Проектирование полезных пространств</Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">
              Маркетинговые исследования рынка и целевой аудитории
            </Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">
              Интеграция со средой и благоустройство территории
            </Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">Выявление потребностей потребителя</Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">Квартирография и эргономика планировок</Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">
              Взаимодействие с партнёрами: поставщиками, банками, инвесторами
            </Text>
            <Hr sx={{ my: 20 }} />
            <Text variant="text1">
              Строительство и реализация объектов недвижимости
            </Text>
            <Hr sx={{ my: 20 }} />
          </Box>
        </Box>
        <Hr sx={{ mt: [100, 200], mb: [20, 50] }} />
        <Box variant="layout.grid2">
          <Box>
            <Text variant="h2">наши ЦЕННОСТИ и отличие проектов</Text>
            <Box sx={{ mt: [20, 40] }}>
              <Box
                sx={{
                  gap: [0, 40],
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr']
                }}
              >
                <Box>
                  <Text variant="text4" sx={{ color: 'gray500' }}>
                    01
                  </Text>
                  <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
                    Рациональность и целостность архитектурной концепции:
                    доказываем ценность объектов
                  </Text>
                </Box>
                <Box sx={{ mt: [10, 0] }}>
                  <Text variant="text4" sx={{ color: 'gray500' }}>
                    02
                  </Text>
                  <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
                    Надёжность: устраивайтесь и расслабляйтесь — никаких
                    сюрпризов после заселения
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: [10, 40] }}>
              <Box
                sx={{
                  gap: [0, 40],
                  display: 'grid',
                  gridTemplateColumns: ['1rf', '1fr 1fr']
                }}
              >
                <Box>
                  <Text variant="text4" sx={{ color: 'gray500' }}>
                    03
                  </Text>
                  <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
                    Удобная эксплуатация: думаем за вас и проектируем
                    дружелюбные решения
                  </Text>
                </Box>
                <Box sx={{ mt: [10, 0] }}>
                  <Text variant="text4" sx={{ color: 'gray500' }}>
                    04
                  </Text>
                  <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
                    Новизна и креатив: следим за мировыми трендами средового
                    дизайна
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  gap: [0, 40],
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  mt: [10, 40]
                }}
              >
                <Box>
                  <Text variant="text4" sx={{ color: 'gray500' }}>
                    05
                  </Text>
                  <Text variant="text1" as="div" sx={{ mt: [10, 20] }}>
                    Сопровождение: поддерживаем клиентов даже после сдачи
                    объектов
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: [40, 0] }}>
            <AnimatedImage
              ratio={useResponsiveValue([280 / 200, 587 / 700])}
              src={useResponsiveValue([
                require('images/i10.jpg').default,
                require('images/i8.jpg').default
              ])}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <Text variant="text4" as="div" sx={{ mb: [10, 0] }}>
          В группу компаний «ГРАНИ» входят:
        </Text>
        <Box data-company-id="1" />
        <Collapse open={id === 1} name={<Text variant="h2">гранит</Text>}>
          <Box variant="layout.grid2" sx={{ pb: [20, 80] }}>
            <Box></Box>
            <Box sx={{ pt: [20, 0] }}>
              <Text variant="text1">
                Заказчик жилой и коммерческой недвижимости, сопровождающий
                получение всех необходимых документов на строительство, ввод,
                передачу, проектирование и строительство объектов
              </Text>
            </Box>
          </Box>
        </Collapse>
        <Box data-company-id="2" />
        <Collapse
          open={id === 2}
          name={<Text variant="h2">СТРОЙ ИНЖИНИРИНГ</Text>}
        >
          <Box variant="layout.grid2" sx={{ pb: [20, 80] }}>
            <Box sx={{ color: 'gray500' }}>
              <ExLink
                href="https://строй-инж.рф/"
                target="_blank"
                size="medium"
              >
                ПЕРЕЙТИ НА САЙТ
              </ExLink>
            </Box>
            <Box sx={{ pt: [20, 0] }}>
              <Text variant="text1">
                Осуществляет действие на территории Самарской области,
                Республике Татарстан, Республике Мордовия, Ульяновске
              </Text>
              <br />
              <br />
              <Text variant="text1">
                <Typo.Ul
                  options={[
                    'Дорожное строительство и благоустройство',
                    'Производство строительных материалов и конструкций',
                    'Комплексная поставка материалов и оборудования на строительные объекты',
                    'Аудит проектной документации Заказчика',
                    'Разработка проектной документации',
                    'Услуги генерального подрядчика',
                    'Услуги строительной лаборатории',
                    'Услуги по аренде опалубки, строительной техники и механизмов',
                    'Услуги по управлению проектами'
                  ]}
                />
              </Text>
              <br />
              <br />
              <Text variant="text1">
                Производство:
                <br />
                <br />
                Внедрён проектный метод работы, для реализации проектов любой
                степени сложности, начиная с разработки технического задания и
                заканчивая вводом объекта в эксплуатацию.
                <br />
                <br />
                Коллектив отличает высокий уровень профессионализма и большой
                опыт строительства разной сложности объектов и инженерных
                сооружений.
                <br />
                <br />
                Имеется необходимое метрологическое, геодезическое и
                материально-техническое обеспечение, нормативно-техническая
                документация.
                <br />
                <br />
                Ведётся контроль, регистрация данных и оценка качества. При
                производстве работ предприятием осуществляется трёх ступенчатый
                контроль качества.
                <br />
                <br />
                На все выполняемые услуги Предприятие имеет свидетельства СРО, в
                том числе на работы, которые оказывают влияние на безопасность
                объектов капитального строительства.
              </Text>
            </Box>
          </Box>
        </Collapse>
        <Box data-company-id="3" />
        <Collapse open={id === 3} name={<Text variant="h2">4ГРАНИ</Text>}>
          <Box variant="layout.grid2" sx={{ pb: [20, 80] }}>
            <Box sx={{ color: 'gray500' }}>
              <ExLink href="https://4grani.com/" size="medium">
                ПЕРЕЙТИ НА САЙТ
              </ExLink>
            </Box>
            <Box sx={{ pt: [20, 0] }}>
              <Text variant="text1">
                Осуществляет производство мелкоштучных бетонных изделий методом
                полусухого вибропрессования и изготавливает брусчатку бортовые,
                стеновые камни, дорожные лотки, элементы ландшафтного дизайна.
                <br />
                <br />
                Продукция бренда «4ГРАНИ» производится на современной,
                высокопроизводительной технологической линии турецкой компании с
                более полувековым опытом производства вибропрессованного
                оборудования «NAMTAS», которая стала первой компанией в Турции
                начавшей серийно производить вибропрессовое оборудование, и за
                65 лет успешно реализовала сотни проектов в 25-ти странах мира.
              </Text>
            </Box>
          </Box>
        </Collapse>
        <Box data-company-id="4" />
        <Collapse open={id === 4} name={<Text variant="h2">БЕТОН ГРАНИТ</Text>}>
          <Box variant="layout.grid2" sx={{ pb: [20, 80] }}>
            <Box>
              <Box sx={{ color: 'gray500' }}>
                <ExLink href="https://betongranit.com/" size="medium">
                  ПЕРЕЙТИ НА САЙТ
                </ExLink>
              </Box>
              <Box sx={{ color: 'gray500', mt: 20 }}>
                <ExLink href="https://kzn.betongranit.com/" size="medium">
                  САЙТ ПРЕДСТАВИТЕЛЬСТВА
                  <br />В КАЗАНИ
                </ExLink>
              </Box>
            </Box>
            <Box sx={{ pt: [20, 0] }}>
              <Text variant="text1">
                Осуществляет производство бетонных и растворных смесей в г.
                Тольятти и г. Казань
              </Text>
            </Box>
          </Box>
        </Collapse>
        <Box data-company-id="5" />
        <Collapse
          open={id === 5}
          name={<Text variant="h2">СТРОИТЕЛЬНАЯ ЛАБОРАТОРИЯ ГОСТ</Text>}
        >
          <Box variant="layout.grid2" sx={{ pb: [20, 80] }}>
            <Box></Box>
            <Box sx={{ pt: [20, 0] }}>
              <Text variant="text1">
                Осуществляет контроль качества в строительстве и на
                производстве, имеет все необходимые допуски и оборудование
              </Text>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="проекты" href="/projects" />
      </Box>
    </Box>
  )
}

export default Company
