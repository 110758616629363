import Gallery from "components/Gallery";
import Table from "components/Table";
import Video from "components/Video";
import { Heading, TextBlock } from "components/Typo.atoms";
import { Fragment } from "react";
import { Box } from "theme-ui";

const ContentBuilder = ({ blocks, projectId }) => {
  return (
    <Box>
      {blocks.map((block, index) => {
        const Component = components.get(block.type);
        return Component ? (
          <Fragment key={index}>
            <Component {...block} projectId={projectId} />
          </Fragment>
        ) : null;
      })}
    </Box>
  );
};

const components = new Map([
  ["gallery", Gallery],
  ["heading", Heading],
  ["text", TextBlock],
  ["table", Table],
  ["video", Video],
]);

export default ContentBuilder;
