/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui'
import { useColorMode } from 'theme-ui'
import { motion } from 'framer-motion/dist/framer-motion'

const Switch = ({ onClick }) => {
  const [colorMode] = useColorMode()
  return (
    <motion.div whileHover="enter" style={{ transform: 'scaleZ(1)' }}>
      <Box onClick={onClick} sx={{ position: 'relative', cursor: 'pointer' }}>
        <motion.div
          initial={{ opacity: 0, x: -30 }}
          variants={{ enter: { opacity: 1, x: 0 } }}
        >
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color: 'gray500',
              position: 'absolute',
              top: '-0.31em',
              right: 8 + 10,
              whiteSpace: 'nowrap'
            }}
          >
            сменить тему
          </Box>
        </motion.div>
        <Box
          sx={{
            width: 10,
            height: 10,
            border: 'solid 2px',
            borderRadius: 10,
            borderColor: colorMode === 'light' ? 'text' : 'gray500'
          }}
        />
      </Box>
    </motion.div>
  )
}

export default Switch
