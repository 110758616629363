/** @jsxImportSource theme-ui */
import Html from 'components/Html'
import Hr from 'components/Hr'
import AnimatedImage from 'components/AnimatedImage'
import NextSection from 'components/NextSection'
import { useParams } from 'react-router-dom'
import { humanDate } from 'lib/helpers'
import { useNewsById } from 'store/news'
import { Box, Text } from 'theme-ui'

const News = () => {
  const { data: news } = useNewsById(useParams().id)
  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      {news && (
        <Box>
          <Text variant="h1" as="h1">
            {news.name}
          </Text>
          <Box variant="layout.grid2" sx={{ mt: [10, 20] }}>
            <Box>
              <Text variant="text1">{news.annotation}</Text>
            </Box>
            <Box
              sx={{
                alignSelf: 'flex-end',
                textAlign: ['left', 'right'],
                mt: [10, 0]
              }}
            >
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textTransform: 'uppercase'
                }}
              >
                {humanDate(news.publishedAt)}
              </Text>
            </Box>
          </Box>
          <Hr sx={{ mt: [20, 80], mb: [30, 40] }} />
          <Box variant="layout.grid2">
            <Box sx={{ mb: [40, 0] }}>
              <AnimatedImage ratio={705 / 398} src={news.pictureUrl} />
            </Box>
            <Box>
              <Text variant="text1">
                <Html source={news.body} />
              </Text>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="Новости" href="/news" />
      </Box>
    </Box>
  )
}

export default News
