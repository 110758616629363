export function formatPhoneNumber(input) {
  const phone = input.toString().replace(/\D/g, "");
  const m = phone.match(/^(7)(\d{4})(\d{3})(\d+)$/);
  if (!m) return m;
  return ["+ " + m[1], " (", m[2], ") ", m[3], "-", m[4]].join("");
}

export function humanDate(
  datetime,
  options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
) {
  const locale = "ru-RU";
  return dateFromMySQL(datetime).toLocaleDateString(locale, options);
}

export function dateFromMySQL(datetime) {
  const [y, m, ...rest] = datetime.split(/[- :]/);
  return new Date(y, m - 1, ...rest);
}

export function getSocialLink(office, socialName) {
  return office?.officeSettings?.social?.[socialName];
}

export function hasSocialLinks(office) {
  return Object.values(office.officeSettings.social).some((val) => val);
}

export function createResponse({ data, error }) {
  return {
    data: data?.data,
    loading: !error && !data?.data,
    error,
  };
}

export function wrap(min, max, v) {
  const range = max - min;
  return ((((v - min) % range) + range) % range) + min;
}

export function localStorageEffect(key) {
  return ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
}

export function safeJSONParse(json) {
  try {
    return JSON.parse(json);
  } catch (ex) {
    return null;
  }
}

export function sanitizeHTML(html) {
  return html;
}
