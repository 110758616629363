import Hr from "components/Hr";
import { Fragment } from "react";
import { Box, Text } from "theme-ui";

const Table = ({ value }) => {
  return (
    <Box variant="layout.grid2" sx={{ mb: [20, 80] }}>
      <Box />
      <Box>
        {value.map((val, index) => {
          return (
            <Fragment key={index}>
              <Text
                variant="text1"
                sx={{ display: "grid", gridTemplateColumns: "1fr auto" }}
              >
                {val.map((v, i) => (
                  <Box key={i}>{v.value}</Box>
                ))}
              </Text>
              <Hr sx={{ mt: [10, 20], mb: [10, 20] }} />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
export default Table;
