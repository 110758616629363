/** @jsxImportSource theme-ui */
import RouterLink from 'components/RouterLink'
import { Box, Text, Link } from 'theme-ui'
import { ReactComponent as ArrowAltIcon } from 'icons/arrow-alt.svg'

const ExLink = ({ href, target, children, size, onClick }) => {
  const LinkComponent = href.startsWith('/') ? RouterLink : Link
  if ('small' === size) {
    return (
      <Text variant="text2" sx={{ color: 'inherit' }}>
        <LinkComponent
          onClick={onClick}
          target={target}
          href={href}
          sx={{
            textDecoration: 'none',
            '&:hover': {
              color: 'text',
              textDecoration: 'none',
              '& [data-name=underline]': {
                borderColor: 'currentColor'
              }
            }
          }}
        >
          <Box
            data-name="underline"
            sx={{ borderBottom: 'solid 1px transparent', display: 'inline' }}
          >
            {children}
          </Box>
          <ArrowAltIcon
            sx={{
              ml: 10,
              width: 13,
              height: 13,
              top: [2, 1],
              position: 'relative'
            }}
          />
        </LinkComponent>
      </Text>
    )
  }
  if ('medium' === size) {
    return (
      <Text variant="text4" sx={{ color: 'inherit' }}>
        <LinkComponent
          onClick={onClick}
          target={target}
          href={href}
          sx={{
            textDecoration: 'none',
            '&:hover': {
              color: 'text',
              textDecoration: 'none',
              '& [data-name=underline]': {
                borderColor: 'currentColor'
              }
            }
          }}
        >
          <Box
            data-name="underline"
            sx={{ borderBottom: 'solid 1px transparent', display: 'inline' }}
          >
            {children}
          </Box>
          <ArrowAltIcon
            sx={{
              ml: [12, 20],
              width: [12, 19],
              height: [12, 19],
              position: 'relative',
              top: 1
            }}
          />
        </LinkComponent>
      </Text>
    )
  }
  if ('large' === size) {
    return (
      <Text variant="h1" sx={{ color: 'inherit' }}>
        <LinkComponent
          onClick={onClick}
          target={target}
          href={href}
          sx={{
            textDecoration: 'none',
            '&:hover': {
              color: 'text',
              textDecoration: 'none',
              '& [data-name=underline]': {
                borderColor: 'currentColor'
              }
            }
          }}
        >
          <Box
            data-name="underline"
            sx={{ borderBottom: 'solid 1px transparent', display: 'inline' }}
          >
            {children}
          </Box>
          <ArrowAltIcon
            sx={{
              ml: [20, 40],
              width: [26, 67],
              height: [26, 67],
              position: 'relative',
              top: 1
            }}
          />
        </LinkComponent>
      </Text>
    )
  }
}

export default ExLink
