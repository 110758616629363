/** @jsxImportSource theme-ui */
import React from "react";
import { ReactComponent as PrevIcon } from "icons/prev.svg";
import { ReactComponent as NextIcon } from "icons/next.svg";
import { Box, Text, Image, AspectRatio, IconButton } from "theme-ui";
import { wrap } from "lib/helpers";

const SliderSm = ({ slides }) => {
  const [[page], setPage] = React.useState([0, 0]);

  const paginate = (d) => setPage([page + d, d]);

  const prev = () => paginate(-1);
  const next = () => paginate(+1);

  const index = wrap(0, slides.length, page);
  const { url, name } = slides[index];

  return (
    <Box>
      <AspectRatio ratio={280 / 386}>
        <Image
          src={url}
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </AspectRatio>
      <Box>
        <Box sx={{ mt: 20, display: "grid", gridTemplateColumns: "1fr auto" }}>
          <Box>
            <Box as="span" sx={{ fontSize: 18 }}>
              {String(index + 1).padStart(2, 0)}
            </Box>
            <Box as="span" sx={{ fontSize: 18, color: "gray500" }}>
              {" "}
              —{" "}
            </Box>
            <Box as="span" sx={{ fontSize: 18, color: "gray500" }}>
              {String(slides.length).padStart(2, 0)}
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={prev}
              sx={{
                mr: 20,
                width: "auto",
                height: "auto",
                cursor: "pointer",
                color: "gray500",
                "&:hover": { color: "text" },
              }}
            >
              <PrevIcon sx={{ width: 17, height: 17 }} />
            </IconButton>
            <IconButton
              onClick={next}
              sx={{
                width: "auto",
                height: "auto",
                cursor: "pointer",
                color: "gray500",
                "&:hover": { color: "text" },
              }}
            >
              <NextIcon sx={{ width: 17, height: 17 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 10 }}>
        <Text variant="h3">{name}</Text>
      </Box>
    </Box>
  );
};

export default SliderSm;
