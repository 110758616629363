/** @jsxImportSource theme-ui */
import Hr from 'components/Hr'
import ExLink from 'components/ExLink'
import NextSection from 'components/NextSection'
import { Box, Text } from 'theme-ui'

const Soglasie = () => {
  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box variant="layout.grid2">
        <Box>
          <Text variant="h3" as="div">
            Согласие на обработку персональных данных пользователей сайта,
            принадлежащего Обществу с ограниченной ответственностью
            Специализированный Застройщик «ГРАНИТ»
          </Text>
          <Box sx={{ mt: [20, 40] }}>
            <ExLink href="/personaltop" size="small">
              ПОЛИТИКА В ОБЛАСТИ ОБРАБОТКИ И ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </ExLink>
          </Box>
        </Box>
        <Box sx={{ display: ['block', 'none'], mt: 20 }}>
          <Hr />
        </Box>
        <Box sx={{ mt: [20, 0] }}>
          <Text variant="text1">
            <p sx={{ mt: '0.25em' }}>
              Настоящим я, далее – «Субъект Персональных Данных», во исполнение
              требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О
              персональных данных» (с изменениями и дополнениями) свободно,
              своей волей и в своем интересе даю согласие ООО Специализированный
              Застройщик «ГРАНИТ», ОГРН 1136324007313, ИНН 6324041030,
              юридический адрес: 445004, РФ, Самарская область, г.Тольятти,
              Ореховый проезд д. 13 (далее – ООО СЗ «ГРАНИТ»), на обработку
              своих персональных данных, указанных при заполнении формы обратной
              связи, регистрации - на сайте (далее – Сайт), направляемой{' '}
              <span>(заполненной) с использованием Сайта.</span>
            </p>
            <p>
              Целью обработки моих персональных данных является: регистрация
              сведений физических лиц (субъектов персональных данных),
              необходимых для осуществления деятельности, по предоставлению
              посреднических услуг при купле-продажи недвижимого имущества за
              вознаграждение или на договорной основе.
            </p>
            <p>Основанием для обработки персональных данных являются:</p>
            <ul>
              <li>
                Ст. 24 Конституции Российской Федерации; ст.6 Федерального
                закона от 27.07.2006 N 152-ФЗ "О персональных данных";
              </li>
              <li>Устав ООО СЗ «ГРАНИТ».</li>
            </ul>
            <p>
              Под персональными данными я понимаю любую информацию, относящуюся
              ко мне как к Субъекту Персональных Данных, в том числе:
            </p>
            <ul>
              <li>мои фамилию, имя, отчество;</li>
              <li>год, месяц, дата и место рождения;</li>
              <li>адрес;</li>
              <li>семейное, социальное, имущественное положение;</li>
              <li>образование;</li>
              <li>профессия;</li>
              <li>доходы;</li>
              <li>
                а также ИНН, паспортные данные, данные медицинского полиса,
                страхового свидетельства, дополнительные сведения,
                предусмотренные условиями договора и требованиями федеральных
                законов, определяющих случаи и особенности обработки
                персональных данных.
              </li>
            </ul>
            <p>
              Под обработкой персональных данных я понимаю сбор, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (предоставление, доступ)
              третьим лицам для достижения целей по заключенным договорам с
              согласия субъекта, чьи персональные данные обрабатываются,
              обезличивание, блокирование, уничтожение персональных данных, а
              также иные действия с персональными данными с учетом действующего
              законодательства.
            </p>
            <p>
              Датой выдачи согласия на обработку персональных данных Субъекта
              Персональных Данных является дата отправки персональных данных,
              указанных при заполнении формы обратной связи, с Сайта.
            </p>
            <p>
              Обработка персональных данных Субъекта Персональных Данных
              осуществляется способом смешенной (автоматизированной,
              неавтоматизированной) обработки (на бумажных, на электронных
              носителях информации и в ИСПДн), с передачей по внутренней сети
              юридического лица, без передачи в сеть общего пользования
              Интернет; на территории Самарской области, код 63.
            </p>
            <p>
              <span>ООО СЗ «ГРАНИТ» </span>принимает необходимые правовые,
              организационные и технические меры или обеспечивает их принятие
              для защиты персональных данных от неправомерного или случайного
              доступа к ним, уничтожения, изменения, блокирования, копирования,
              предоставления, распространения персональных данных, а также от
              иных неправомерных действий в отношении персональных данных, а
              также принимает на себя обязательство сохранения
              конфиденциальности персональных данных Субъекта Персональных
              Данных.
            </p>
            <p>
              <span>ООО СЗ «ГРАНИТ»</span> вправе либо в предусмотренных законом
              случаях обязан передавать персональные данные для обработки
              контролирующим органам государственной власти и местного
              самоуправления, кредитным организациям, индивидуальному
              предпринимателю Насыртдинову Р.И. ОГРНИП 320631300116878, ООО
              "Регистратор доменных имен РЕГ.РУ" ОГРН 1067746613494 обеспечивая
              при этом принятие такими лицами соответствующих обязательств в
              части конфиденциальности персональных данных.
            </p>
            <p>Я ознакомлен(а), что:</p>
            <p>
              настоящее согласие на обработку моих персональных данных,
              указанных при заполнении формы обратной связи, регистрации на
              Сайте <span>ООО СЗ «ГРАНИТ»</span>, направляемых (заполненных) с
              использованием Cайта, действует в течение 5 (пяти) лет с момента
              регистрации на Cайте;
            </p>
            <p>
              согласие может быть отозвано мною на основании письменного
              заявления в произвольной форме;
            </p>
            <p>
              предоставление персональных данных третьих лиц без их согласия
              влечет ответственность в соответствии с действующим
              законодательством Российской Федерации.
            </p>
            <p>
              В случае отзыва субъектом персональных данных или его
              представителем согласия на обработку персональных данных{' '}
              <span>ООО СЗ «ГРАНИТ»</span> вправе продолжить обработку
              персональных данных без согласия субъекта персональных данных при
              наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6,
              части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ
              «О персональных данных» от 26.06.2006 г.
            </p>
            <p sx={{ mb: 0 }}>
              Настоящее согласие действует в течение 5 (пяти) лет, либо до
              момента отзыва обработки персональных данных.
            </p>
          </Text>
        </Box>
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="проекты" href="/projects" />
      </Box>
    </Box>
  )
}

export default Soglasie
