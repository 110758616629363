/** @jsxImportSource theme-ui */
import RouterLink from 'components/RouterLink'
import Itf from 'components/Itf'
import ToggleSwitch from 'components/ToggleSwitch'
import { useSettings } from 'store/settings'
import { Box, Text, Link } from 'theme-ui'
import { formatPhoneNumber } from 'lib/helpers'
import { ReactComponent as LogoIcon } from 'icons/logo.svg'
import { useColorMode } from 'theme-ui'

const Footer = ({ sx, ...props }) => {
  const { data: settings } = useSettings()
  const address = (settings?.addressOffice ?? '').replace(/г. Тольятти/, '\n$&')

  const [colorMode, setColorMode] = useColorMode()

  return (
    <Box as="footer" sx={{ pb: 40, ...sx }} {...props}>
      <Box variant="layout.grid2" sx={{ position: 'relative' }}>
        <Box sx={{ mb: [55, 0] }}>
          <LogoIcon sx={{ width: [120, 165], height: [36, 50] }} />
          <Box sx={{ display: ['block', 'none'], mt: 20 }}>
            <ToggleSwitch
              offText="Темная"
              onText="Светлая"
              switchOn={colorMode === 'light'}
              onSwitchOn={() => setColorMode('light')}
              onSwitchOff={() => setColorMode('dark')}
            />
          </Box>
        </Box>
        {settings && (
          <Box>
            <Box
              sx={{
                gap: 30,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr']
              }}
            >
              <Box>
                <Text
                  variant="text1"
                  sx={{ color: 'gray500', mb: 13 }}
                  as="div"
                >
                  Контакты
                </Text>
                <Text variant="text1" as="div">
                  {settings.phone && (
                    <Link
                      href={`tel:${settings.phone}`}
                      sx={{
                        color: 'inherit',
                        textDecoration: 'none',
                        '&:hover': { textDecoration: 'underline' }
                      }}
                    >
                      {formatPhoneNumber(settings.phone)}
                    </Link>
                  )}
                </Text>
                <Text variant="text1" as="div">
                  &nbsp;
                </Text>
                <Text variant="text1" as="div">
                  {settings.email && (
                    <Link
                      href={`mailto:${settings.email}`}
                      sx={{
                        color: 'inherit',
                        textDecoration: 'none',
                        '&:hover': { textDecoration: 'underline' }
                      }}
                    >
                      {settings.email}
                    </Link>
                  )}
                </Text>
              </Box>
              <Box>
                <Text
                  variant="text1"
                  sx={{ color: 'gray500', mb: 13 }}
                  as="div"
                >
                  Адрес
                </Text>
                <Text variant="text1" as="div" sx={{ whiteSpace: 'pre-line' }}>
                  {address}
                </Text>
              </Box>
            </Box>
            <Box
              sx={{
                pt: 40,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr auto']
              }}
            >
              <Text variant="text1" sx={{ color: 'gray500' }}>
                <RouterLink href="/personaltop">
                  Политика конфиденциальности
                </RouterLink>
              </Text>
              <Box
                sx={{
                  mr: 2,
                  mt: [18, 5],
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Itf />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Footer
