/** @jsxImportSource theme-ui */
import Collapse from 'components/Collapse'
import ExLink from 'components/ExLink'
import NextSection from 'components/NextSection'
import { useDocumentsFindAll } from 'store/documents'
import { Box, Text } from 'theme-ui'
import { ReactComponent as DocIcon } from 'icons/doc.svg'

const Documents = () => {
  const { data: documents } = useDocumentsFindAll()

  return (
    <Box sx={{ pb: 20, pt: [90, 160] }}>
      <Box sx={{ mb: [20, 40] }}>
        <Text variant="h1" as="h1">
          Документы
        </Text>
      </Box>
      <Box sx={{ mb: [20, 40] }} />
      {documents &&
        Object.keys(documents).map(key => (
          <Collapse key={key} name={<Text variant="h2">{key}</Text>}>
            {documents[key].map((section, index) => (
              <Box key={index}>
                <Box sx={{ mt: [20, 40], mb: [20, 30] }} />
                <Box variant="layout.grid2">
                  <Box>
                    <Text variant="text4" as="h2">
                      {section.name}
                    </Text>
                  </Box>
                  <Box>
                    {section.documents.map((document, index) => (
                      <Box
                        key={index}
                        onClick={() => window.open(document.fileUrl)}
                        sx={{
                          mt: [20, 0],
                          cursor: 'pointer',
                          display: 'grid',
                          gridTemplateColumns: 'auto 1fr auto',
                          '&+&': { mt: [40, 20] }
                        }}
                      >
                        <Box sx={{ pr: 20 }}>
                          <DocIcon sx={{ width: 45, height: 55 }} />
                        </Box>
                        <Box sx={{ pr: [0, 100] }}>
                          <Text
                            as="h3"
                            sx={{
                              fontSize: [14, 16],
                              fontWeight: [500, 600],
                              textTransform: ['none', 'uppercase']
                            }}
                          >
                            {document.name}
                          </Text>
                        </Box>
                        <Box sx={{ display: ['none', 'block'], color: 'gray500' }}>
                          <ExLink
                            href={document.fileUrl}
                            target="_blank"
                            size="small"
                            onClick={ev => ev.preventDefault()}
                          >
                            Скачать
                          </ExLink>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
            <Box sx={{ mt: [20, 40], mb: [20, 30] }} />
          </Collapse>
        ))}
      <Box sx={{ mt: [100, 200] }}>
        <NextSection name="Компания" href="/company" />
      </Box>
    </Box>
  )
}

export default Documents
