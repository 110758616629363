import ExLink from 'components/ExLink'
import RouterLink from 'components/RouterLink'
import Hr from 'components/Hr'
import { useCookieInformerActions } from 'actions'
import { Box, Text } from 'theme-ui'

const CookieInformer = () => {
  const cookieInformerActions = useCookieInformerActions()
  return (
    <Box
      sx={{
        px: [20, 40],
        pt: 18,
        pb: [40, 18],
        bg: 'primary'
      }}
    >
      <Hr />
      <Box
        sx={{
          gap: 24,
          mt: 17,
          display: 'grid',
          gridTemplateColumns: ['auto', '1fr auto']
        }}
      >
        <Box>
          <Text as="div" sx={{ maxWidth: 1200 }} variant="text1">
            Наш сайт использует файлы cookie и похожие технологии, предоставляя
            персональную информацию. При использовании данного сайта, Вы
            подтверждаете свое согласие на использование cookie файлов и
            обработку персональных общедоступных данных согласно{' '}
            <RouterLink
              href="/personaltop"
              sx={{ textDecoration: 'underline' }}
            >
              Политике&nbsp;конфиденциальности
            </RouterLink>
          </Text>
        </Box>
        <Box>
          <ExLink
            href="#"
            size="small"
            onClick={ev => {
              ev.preventDefault()
              cookieInformerActions.close()
            }}
          >
            Я согласен
          </ExLink>
        </Box>
      </Box>
    </Box>
  )
}

export default CookieInformer
